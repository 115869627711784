import {
  BookingUnion,
  PaymentTransaction,
  ServerMovementProductUpdates,
  ServerProductType,
  ServerTransactionInsertInput,
  TRANSACTION_TYPES,
} from '@expane/data'
import { calcDiscountSum, DEFAULT_DISCOUNT, DiscountInputInfo } from '../../utils'
import { convertUnitValueFromServer } from '../../product'
import { countProductAmountsInTransactions, getIsProductPaid } from '../../booking'

export type ConsumableDto = {
  id: number
  productId: number
  quantity: string
  name: string
  price: number
  discount: DiscountInputInfo
  bookingId: number
  unit: number
}

export const calcConsumableTotalSum = (consumablesDto: ConsumableDto[]) => {
  const result = consumablesDto.reduce((sum, item) => {
    const convertedQuantity = convertUnitValueFromServer(Number(item.quantity), item.unit)

    const itemDiscount = calcDiscountSum({
      discount: item.discount,
      price: item.price,
      quantity: convertedQuantity,
    })
    const totalPrice = item.price * convertedQuantity

    return sum + (totalPrice - itemDiscount)
  }, 0)

  return result
}

export const formConsumablePaymentTransactions = ({
  booking,
  clientId,
  employeeId,
  filteredOptionalConsumables,
  debt,
  branchId,
}: {
  debt: number
  booking: BookingUnion
  clientId: number
  employeeId: number | undefined
  filteredOptionalConsumables: ConsumableDto[]
  branchId: number
}): {
  transactions: PaymentTransaction[]
  consumablesUpdates: ServerMovementProductUpdates[]
} => {
  const transactions: PaymentTransaction[] = []
  const consumablesUpdates: ServerMovementProductUpdates[] = []
  let mutableDebt = debt

  for (const consumable of filteredOptionalConsumables) {
    const theSameMovement = booking.movements.find(
      movement =>
        movement.movementProducts.length === 1 &&
        !movement.serviceId &&
        movement.movementProducts[0].productId === consumable.productId &&
        movement.movementProducts[0].quantity === Number(consumable.quantity),
    )

    // В нас повинно буте це переміщення
    if (!theSameMovement) continue

    // Якщо додаємо знижку то треба поправити movementProducts
    if (consumable.discount.value) {
      const discount = calcDiscountSum({
        discount: consumable.discount,
        price: consumable.price,
        quantity: 1,
      })

      const price = consumable.price - discount

      consumablesUpdates.push({
        where: { id: { _eq: theSameMovement.movementProducts[0].id } },
        _set: { price, discount },
      })
    }

    const amount = calcConsumableTotalSum([consumable])

    const paymentIsInCredit = mutableDebt > 0
    const childTransactions: ServerTransactionInsertInput[] | null =
      paymentIsInCredit && amount > debt
        ? [
            {
              amount: amount - debt,
              type: TRANSACTION_TYPES.repaymentDeposit.id,
              typeVariation:
                TRANSACTION_TYPES.repaymentDeposit.variations.repaymentDepositByClientBalance.id,
              clientId,
              branchId,
            },
          ]
        : null

    transactions.push({
      amount,
      type: paymentIsInCredit ? TRANSACTION_TYPES.paymentInCredit.id : TRANSACTION_TYPES.payment.id,
      typeVariation: TRANSACTION_TYPES.payment.variations.product.id,
      bookingId: booking.id,
      clientId,
      employeeId,
      movementId: theSameMovement.id,
      childTransactions: childTransactions ? { data: childTransactions } : null,
      branchId,
    })

    if (mutableDebt > 0) mutableDebt = mutableDebt - amount
  }

  return { transactions, consumablesUpdates }
}

export const prepareConsumablesDto = (
  bookings: BookingUnion[] | undefined,
  products: ServerProductType[] | undefined,
): ConsumableDto[] => {
  if (!bookings || bookings.length === 0 || !products) return []

  const result: ConsumableDto[] = []

  for (const booking of bookings) {
    const paymentTransactions = booking.transactions.filter(
      transaction => transaction.type === TRANSACTION_TYPES.payment.id,
    )
    const paidProductAmounts = countProductAmountsInTransactions(paymentTransactions)

    const refundTransactions = booking.transactions.filter(
      transaction => transaction.type === TRANSACTION_TYPES.refund.id,
    )
    const refundedProductAmounts = countProductAmountsInTransactions(refundTransactions)

    booking.bookingProducts.forEach(({ productId, quantity }) => {
      if (getIsProductPaid({ productId, quantity }, paidProductAmounts, refundedProductAmounts))
        return

      const quantityToPay =
        quantity - ((paidProductAmounts[productId] ?? 0) - (refundedProductAmounts[productId] ?? 0))
      const product = products.find(product => product.id === productId)

      if (product)
        result.push({
          id: product.id,
          name: product.name,
          productId,
          price: product.price,
          bookingId: booking.id,
          quantity: quantityToPay.toString(),
          discount: DEFAULT_DISCOUNT,
          unit: product.unit,
        })
    })
  }

  return result
}
