import { gql } from 'graphql-request'
import { marketingCampaignForReferrerFragment } from '../marketingCampaign'

export const leadContactFragment = gql`
  fragment leadContactType on leadContact {
    id
    firstName
    lastName
    email
    phone
    details
    role
  }
`

export const leadHistoryFragment = gql`
  ${marketingCampaignForReferrerFragment}
  fragment leadHistoryType on leadHistory {
    id
    createdAt
    primary
    url
    additionalTags {
      id
      key
      value
    }
    marketingCampaign {
      ...marketingCampaignForReferrerType
    }
  }
`

export const leadBusinessInfoForReferrerFragment = gql`
  fragment leadBusinessInfoForReferrerType on businessInfoReferrerView {
    name
    createdAt
    expirationDate
    active
    planId
    totalPurchase
  }
`

export const leadBusinessFragment = gql`
  ${leadBusinessInfoForReferrerFragment}
  fragment leadBusinessType on leadBusiness {
    businessId
    info {
      ...leadBusinessInfoForReferrerType
    }
  }
`

export const leadForReferrerFragment = gql`
  ${leadContactFragment}
  ${leadHistoryFragment}
  ${leadBusinessFragment}
  fragment leadForReferrerType on lead {
    id
    contacts {
      ...leadContactType
    }
    histories {
      ...leadHistoryType
    }
    business {
      ...leadBusinessType
    }
  }
`
