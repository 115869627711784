import { getCookie, setCookie } from '../cookies'

const LEAD_COOKIE_KEY = 'expane_lead'
const DEV_LEAD_COOKIE_KEY = 'expane_dev_lead'

// `local` нам потрібен коли ми працюємо локально під час розробки
type AppMode = 'local' | 'development' | 'production'

const cookieParamsMap: Record<AppMode, { key: string; domain: string }> = {
  local: {
    key: DEV_LEAD_COOKIE_KEY,
    domain: 'localhost',
  },
  development: {
    key: DEV_LEAD_COOKIE_KEY,
    domain: '.expane.pro',
  },
  production: {
    key: LEAD_COOKIE_KEY,
    domain: '.expane.pro',
  },
}

export interface ConfigType {
  LOCAL_RUN: boolean
  ENV: string | undefined
}

export const getCookieParams = (config: ConfigType) => {
  const appMode: AppMode = config.LOCAL_RUN
    ? 'local'
    : config.ENV === 'development'
    ? 'development'
    : 'production'

  return cookieParamsMap[appMode]
}

const DAYS_TO_STORE_COOKIES = 360 // Майже рік
export const COOKIE_MAX_AGE = 60 * 60 * 24 * DAYS_TO_STORE_COOKIES // cookies Max Age задається в секундах

export const safeCookieParse = (cookie: string): string | null => {
  try {
    const decodedCookie = decodeURIComponent(cookie)
    const leadId = decodedCookie.split(';')[0].split('=')[1]

    if (leadId) {
      return leadId
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const getLeadIdFromCookie = (config: ConfigType) => {
  const cookieParams = getCookieParams(config)

  const rawCookie = getCookie(cookieParams.key)

  if (rawCookie) return safeCookieParse(rawCookie)

  return null
}

export const removeLeadCookie = (config: ConfigType) => {
  const cookieParams = getCookieParams(config)

  setCookie({
    key: cookieParams.key,
    value: '',
    domain: cookieParams.domain,
    // Ми не можемо напряму видалити cookie, але ставимо max-age в 1 сек, щоб браузер сам очистив
    maxAge: 1,
  })
}
