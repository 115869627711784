import {
  CARD_TYPES,
  DataForCheckboxReceipt,
  ServerAccountType,
  useCheckboxCreateReceipt,
  useCreateTransactions,
  useFetchBranchById,
  useFetchClientBalance,
  useFetchExtendedEmployees,
  useFetchProducts,
} from '@expane/data'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { calcDebtAmount, CLIENT_ACCOUNT_ID, PAYMENT_OPTIONS } from '@expane/logic/payment'
import {
  getPaymentType,
  handleUpdateCreateReceiptResponse,
  transformQuickSaleFormValuesForCheckboxReceipt,
} from '@expane/logic/payment/checkbox'
import { Button, CloseButton, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { generateUUIDv4 } from '@expane/web-logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, SubmitHandler, UseFormHandleSubmit, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { reportError } from 'services/api'
import { store } from 'store'
import { useGiftCardSaleNotice } from 'widgets/GiftCardSaleNotice'
import { SubmitQuickSaleDialogFormValues } from './logic'
import {
  generateDepositTransactionsForQuickSale,
  generatePaymentTransactionsForQuickSale,
} from './submitLogic'

interface QuickSaleDialogFooterProps {
  control: Control<SubmitQuickSaleDialogFormValues>
  handleSubmit: UseFormHandleSubmit<SubmitQuickSaleDialogFormValues>
  accounts: ServerAccountType[]
  disabledSubmit: boolean
  showSpinner: boolean
  closeDialog: () => void
}

export const QuickSaleDialogFooter: FC<QuickSaleDialogFooterProps> = observer(
  ({ control, closeDialog, disabledSubmit, showSpinner, accounts, handleSubmit }) => {
    const { t } = useTranslation()
    const branchId = store.branch.branchId

    const { data: allProducts } = useFetchProducts(branchId)

    const { giftCardSaleNotice, openGiftCardSaleNotice } = useGiftCardSaleNotice()

    const { data: branch } = useFetchBranchById(branchId)
    const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)

    const watchedClientId = useWatch({ control, name: 'clientId' })
    const { data: clientBalance } = useFetchClientBalance(watchedClientId, branchId)

    const { getModuleSetting } = useBusinessModulesSettings()
    const isCheckboxEnabled = getModuleSetting('checkbox')

    const { mutateAsync: createTransactions } = useCreateTransactions()
    const { mutateAsync: createReceipt } = useCheckboxCreateReceipt()

    const [openSnackBar] = useSnackbar()

    const {
      warningModal: severalAccountsWarningModal,
      showWarningPopup: showSeveralAccountsWarningPopup,
    } = useShowWarningPopup(
      t('softwarePOS.severalAccountsWarningTitle'),
      t('softwarePOS.severalAccountsWarningDescription'),
    )

    const submitQuickSale: SubmitHandler<SubmitQuickSaleDialogFormValues> = async data => {
      if (!branchId) {
        openSnackBar(t('payment.warning'), 'error', 3000)

        return
      }

      const paymentType = isCheckboxEnabled
        ? getPaymentType({
            paymentOption: data.paymentOption,
            paymentToAccounts: data.paymentToAccounts,
            accountId: data.accountId,
            accounts,
          })
        : undefined
      if (paymentType && paymentType.type === 'softwarePOS' && paymentType.error) {
        showSeveralAccountsWarningPopup()

        return
      }

      let dataForCheckboxReceipt: DataForCheckboxReceipt | undefined = undefined
      if (paymentType && paymentType.type === 'softwarePOS') {
        dataForCheckboxReceipt = transformQuickSaleFormValuesForCheckboxReceipt({
          data,
          softwarePOSId: paymentType.softwarePOSId,
          generateUUIDv4,
        })
      }

      try {
        const { clientId } = data
        const debt = calcDebtAmount({
          severalAccounts: data.paymentOption.id === PAYMENT_OPTIONS[1].id,
          paymentAmount: Number(data.paymentAmount),
          paymentAmounts: data.paymentToAccounts,
          clientBalance: clientBalance ?? 0,
          byClientBalance: data.accountId === CLIENT_ACCOUNT_ID,
        })

        const depositTransactions = generateDepositTransactionsForQuickSale(
          data,
          accounts,
          branchId,
        )

        let receiptId: string | undefined
        if (dataForCheckboxReceipt) {
          const result = await createReceipt(dataForCheckboxReceipt)

          receiptId = handleUpdateCreateReceiptResponse({
            t,
            onError: message => openSnackBar(message, 'error', 3000),
            createReceiptResult: result.checkboxCreateReceipt,
          })

          if (!receiptId) return
        }

        const paymentTransactions = generatePaymentTransactionsForQuickSale({
          data,
          allProducts,
          employees,
          branch,
          debt,
          branchId,
        })

        const modifiedPaymentTransactions =
          receiptId && dataForCheckboxReceipt?.softwarePOSId
            ? paymentTransactions.map(transaction => ({
                ...transaction,
                transactionReceipts: {
                  data: [{ receiptId, softwarePOSId: dataForCheckboxReceipt?.softwarePOSId }],
                },
              }))
            : paymentTransactions

        const { insertTransactions } = await createTransactions({
          paymentTransactions: [...depositTransactions, ...modifiedPaymentTransactions],
        })
        if (!insertTransactions) throw new Error('Transactions were not inserted')
        const { returning: createdTransactions } = insertTransactions

        // проданные сертификаты для уведомления с кодом активации
        const giftCardSaleIds: number[] =
          createdTransactions?.reduce((ids, transaction) => {
            const { transactionsCards } = transaction
            if (!transactionsCards) return ids

            for (const transactionCard of transactionsCards) {
              if (transactionCard.card.type === CARD_TYPES.giftCard)
                ids.push(transactionCard.card.id)
            }
            return ids
          }, [] as number[]) ?? []

        if (giftCardSaleIds.length) {
          openGiftCardSaleNotice(clientId, giftCardSaleIds, closeDialog)
        } else {
          closeDialog()
        }

        openSnackBar(t('payment.successful'), 'success', 3000)
      } catch (error) {
        openSnackBar(t('payment.warning'), 'error', 3000)
        reportError(error as Error, 'error', {
          data,
        })
      }
    }

    return (
      <>
        <Button
          onClick={handleSubmit(submitQuickSale)}
          disabled={disabledSubmit}
          spinner={showSpinner}
          Icon={IoCashOutline}
        >
          {t('sell')}
        </Button>

        <CloseButton className="mr-auto" onClick={closeDialog} />
        {giftCardSaleNotice}
        {severalAccountsWarningModal}
      </>
    )
  },
)
