import { useGetLeftovers } from '@expane/data'
import { checkUnitCanBeFloat, convertUnitValueToServer, getUnitsName } from '@expane/logic/product'
import { DeleteButtonInTable, NumberInput, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ProductsMovementProps } from 'widgets/MovementCreateDialogs/ProductsMovementDialog'
import { ProductDto } from '../ChooseMovementProductsDialog'
import { LeftoverCell } from '../LeftoverCell'

export const ProductsMovementProductsList: FC<ProductsMovementProps> = observer(({ control }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const fromStorageId = useWatch({ control, name: 'fromStorageId' })
  const toStorageId = useWatch({ control, name: 'toStorageId' })

  const { data: productLeftoversFromStorage } = useGetLeftovers(branchId, fromStorageId)
  const { data: productLeftoversToStorage } = useGetLeftovers(branchId, toStorageId)

  const leftoverProductsFromStorage =
    fromStorageId && productLeftoversFromStorage ? productLeftoversFromStorage : undefined

  const leftoverProductsToStorage =
    toStorageId && productLeftoversToStorage ? productLeftoversToStorage : undefined

  const { fields, remove } = useFieldArray({ control, name: 'products', keyName: 'generatedId' })
  const isThereOneField = fields.length === 1

  const columns = useMemo<ColumnDef<ProductDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('product.name'),
        size: 440,
      },
      {
        id: 'quantity',
        header: t('qty'),
        size: 100,
        cell: data => {
          const index = data.row.index
          const product = data.row.original
          return (
            <Controller
              control={control}
              name={`products.${index}` as 'products.0'}
              rules={{
                validate: {
                  biggerThanZero: value =>
                    value.quantity > 0 &&
                    convertUnitValueToServer(value.quantity, value.unit) <=
                      (leftoverProductsFromStorage?.[product.id]?.available ?? 0),
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <NumberInput
                  onChange={value => {
                    onChange({ ...product, quantity: Number(value) })
                  }}
                  value={value.quantity.toString()}
                  errorMessage={{
                    isShown: Boolean(error),
                    text: ' ',
                    reserveIndent: false,
                  }}
                  height="small"
                  allowDecimals={checkUnitCanBeFloat(value.unit)}
                />
              )}
            />
          )
        },
      },
      {
        accessorKey: 'unit',
        header: t('unit.shortName'),
        cell: data => getUnitsName(data.getValue<number>(), t),
        size: 40,
      },
      {
        id: 'fromStorage',
        header: t('storage.current'),
        size: 160,
        cell: data => (
          <ProductsMovementFromStorageLeftover product={data.row.original} control={control} />
        ),
      },
      {
        id: 'toStorage',
        header: t('storage.destination'),
        size: 160,
        cell: data => (
          <ProductsMovementToStorageLeftover product={data.row.original} control={control} />
        ),
      },
      {
        id: 'removeButton',
        cell: data => {
          return (
            <DeleteButtonInTable
              onClick={() => remove(data.row.index)}
              disabled={isThereOneField}
            />
          )
        },
        size: 20,
        enableResizing: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, isThereOneField, leftoverProductsFromStorage, remove, t, leftoverProductsToStorage],
  )

  return <Table containerClassName="max-h-64" columns={columns} data={fields} />
})

const ProductsMovementFromStorageLeftover: FC<ProductsMovementProps & { product: ProductDto }> = ({
  product,
  control,
}) => {
  const fromStorageId = useWatch({ control, name: 'fromStorageId' })

  return <LeftoverCell product={product} storageId={fromStorageId} />
}

const ProductsMovementToStorageLeftover: FC<ProductsMovementProps & { product: ProductDto }> = ({
  product,
  control,
}) => {
  const fromStorageId = useWatch({ control, name: 'toStorageId' })

  return <LeftoverCell product={product} storageId={fromStorageId} />
}
