import { FC, memo, useLayoutEffect, useRef, useState } from 'react'
import { IoCopy } from 'react-icons/io5'
import { getPlacementStylesForArrow, modalsHTMLElement } from '@expane/ui'
import { createPortal } from 'react-dom'
import { arrow, flip, offset, shift, Side, useFloating } from '@floating-ui/react-dom'
import { useTranslation } from 'react-i18next'

export const URLContainer: FC<{ url: string; className?: string }> = memo(({ url, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const { t } = useTranslation()

  const handleClick = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true)
    })
  }

  const [floatingRef, setFloatingRef] = useState<HTMLDivElement | null>(null)

  const arrowRef = useRef(null)
  const {
    reference,
    floating,
    strategy,
    x,
    y,
    middlewareData: { arrow: arrowData, shift: shiftData },
    placement,
  } = useFloating({
    middleware: [flip(), offset(5), arrow({ element: arrowRef }), shift()],
  })

  useLayoutEffect(() => {
    if (floatingRef) {
      floating(floatingRef)
    }
  }, [floatingRef, floating])

  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData, shiftData)

  const arrowStyle =
    'absolute transform rotate-45 w-2 h-2 bg-white border-l border-t dark:border-t-gray-400 border-t-gray-400 dark:border-l-gray-400 border-l-gray-400'
  const indentStyle = 'absolute left-0 w-full h-5' + (side === 'bottom' ? ' -top-5' : ' -bottom-5')

  return (
    <button
      onClick={e => {
        e.stopPropagation()
        handleClick()
      }}
      onMouseLeave={() => {
        setIsOpen(false)
        setIsCopied(false)
      }}
      className={'flex w-full gap-2 justify-between ' + (className || '')}
    >
      <span className="text-left dark:text-white truncate grow flex-1">{url}</span>

      <div ref={reference} className="cursor-pointer" onMouseEnter={() => setIsOpen(true)}>
        <IoCopy className={'text-gray-400 dark:text-white shrink-0'} size={'1.2rem'} />
      </div>

      {isOpen &&
        createPortal(
          <div
            ref={setFloatingRef}
            style={{
              position: strategy,
              top: y ?? 0 + 'px',
              left: x ?? 0 + 'px',
            }}
            className="border border-gray-400 bg-white dark:bg-white dark:text-gray-800 text-gray-400 px-2 py-1 text-sm rounded-md"
          >
            <div ref={arrowRef} style={arrowPlacementStyles} className={arrowStyle} />
            <div className={indentStyle} />
            <p>{isCopied ? t('copied') : t('copy')}</p>
          </div>,
          modalsHTMLElement,
        )}
    </button>
  )
})
