import {
  BookingUnion,
  GroupBooking,
  ServerBookingAsClientType,
  ServerRecurringBookingByIdType,
  ServerTransactionByIdType,
  ServerTransactionInBookingType,
  ServerTransactionType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { useStartOfCurrentDay } from '@expane/date'
import { permissions } from '../permission'
import { useTranslation } from 'react-i18next'
import { convertUnitValueFromServer } from '../product'

export const BOOKING_STATUSES = {
  booking: 0,
  notified: 1,
  inPlace: 2,
  didNotCome: 3,
  done: 4,
}

const countTransactionsByService = (transactions: ServerTransactionInBookingType[]) => {
  // key - serviceId, value - transactions count
  const result: Record<number, number | undefined> = {}

  transactions.forEach(transaction => {
    transaction.transactionsServices.forEach(tS => {
      if (result[tS.serviceId] !== undefined) {
        // @ts-expect-error ts не понимает что мы уже проверили на undefined
        result[tS.serviceId] += 1
      } else {
        result[tS.serviceId] = 1
      }
    })
  })

  return result
}

export const getCountOfPaymentAndRefundTransactionsByService = (
  transactions: ServerTransactionInBookingType[],
) => {
  const paymentsByService = countTransactionsByService(
    transactions.filter(
      transaction =>
        transaction.type === TRANSACTION_TYPES.payment.id ||
        transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
    ),
  )

  const refundsByService = countTransactionsByService(
    transactions.filter(transaction => transaction.type === TRANSACTION_TYPES.refund.id),
  )

  return { paymentsByService, refundsByService }
}

export const getIsServicePaid = ({
  serviceId,
  paymentsByService,
  refundsByService,
}: {
  serviceId: number
  paymentsByService: Record<number, number | undefined>
  refundsByService: Record<number, number | undefined>
}) => {
  const countOfPayments = paymentsByService[serviceId]
  const countOfRefunds = refundsByService[serviceId]

  if (countOfPayments === 0 || countOfPayments === undefined) return false

  if (countOfRefunds === undefined) return true

  return countOfPayments > countOfRefunds
}

export const getIsAllServicesPaid = (
  services: { id: number }[],
  transactions: ServerTransactionInBookingType[],
) => {
  const { paymentsByService, refundsByService } =
    getCountOfPaymentAndRefundTransactionsByService(transactions)

  return services.every(service =>
    getIsServicePaid({ serviceId: service.id, paymentsByService, refundsByService }),
  )
}

export const countProductAmountsInTransactions = (
  transactions: ServerTransactionInBookingType[],
) => {
  // key - serviceId, value - overall product quantity
  const result: Record<number, number> = {}

  transactions.forEach(transaction => {
    transaction.movement?.movementProducts.forEach(({ productId, quantity }) => {
      if (Object.prototype.hasOwnProperty.call(result, productId)) {
        result[productId] += quantity
      } else {
        result[productId] = quantity
      }
    })
  })

  return result
}

export type ProductCheckDto = {
  productId: number
  quantity: number
}

export const getIsProductPaid = (
  productDto: ProductCheckDto,
  paidProductAmounts: Record<number, number>,
  refundedProductAmounts: Record<number, number>,
) => {
  const paidProductAmount = paidProductAmounts[productDto.productId]
  const refundedProductAmount = refundedProductAmounts[productDto.productId]

  if (paidProductAmount === undefined) return false

  if (refundedProductAmount === undefined) return paidProductAmount >= productDto.quantity

  return paidProductAmount - refundedProductAmount >= productDto.quantity
}

export const getIsAllProductsPaid = (
  products: ProductCheckDto[],
  transactions: ServerTransactionInBookingType[],
) => {
  const paymentTransactions = transactions.filter(
    transaction =>
      transaction.type === TRANSACTION_TYPES.payment.id ||
      transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
  )
  const paidProductAmounts = countProductAmountsInTransactions(paymentTransactions)

  const refundTransactions = transactions.filter(
    transaction => transaction.type === TRANSACTION_TYPES.refund.id,
  )
  const refundedProductAmounts = countProductAmountsInTransactions(refundTransactions)

  return products.every(product =>
    getIsProductPaid(product, paidProductAmounts, refundedProductAmounts),
  )
}

export const getIsBookingPaid = (booking: BookingUnion | undefined) => {
  if (!booking) return false
  if (booking.isGroupBooking) return checkGroupBookingForPayment(booking)

  const services = booking.bookingServices.map(({ service }) => service)

  return (
    getIsAllServicesPaid(services, booking.transactions) &&
    getIsAllProductsPaid(booking.bookingProducts, booking.transactions)
  )
}

export const checkGroupBookingForPayment = (booking: GroupBooking) => {
  return booking.bookingClients.length
    ? booking.bookingClients.every(({ client }) => {
        if (booking.service?.id)
          return getIsAllServicesPaid(
            [{ id: booking.service.id }],
            booking.transactions.filter(transaction => transaction.clientId === client.id),
          )
        return false
      })
    : false
}

export const checkIfClientHasPaid = (booking: GroupBooking | undefined, clientId: number) => {
  return booking?.isGroupBooking && booking.service?.id
    ? getIsAllServicesPaid(
        [{ id: booking.service.id }],
        booking.transactions.filter(transaction => transaction.clientId === clientId),
      )
    : false
}

export const getIsSomeServicesPaid = (
  services: { id: number }[],
  transactions: ServerTransactionInBookingType[],
) => {
  const { paymentsByService, refundsByService } =
    getCountOfPaymentAndRefundTransactionsByService(transactions)

  return services.some(service =>
    getIsServicePaid({ serviceId: service.id, paymentsByService, refundsByService }),
  )
}

export const getIsSomeProductsPaid = (
  products: ProductCheckDto[],
  transactions: ServerTransactionInBookingType[],
) => {
  const paymentTransactions = transactions.filter(
    transaction =>
      transaction.type === TRANSACTION_TYPES.payment.id ||
      transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
  )
  const paidProductAmounts = countProductAmountsInTransactions(paymentTransactions)

  const refundTransactions = transactions.filter(
    transaction => transaction.type === TRANSACTION_TYPES.refund.id,
  )
  const refundedProductAmounts = countProductAmountsInTransactions(refundTransactions)

  return products.some(product =>
    getIsProductPaid(product, paidProductAmounts, refundedProductAmounts),
  )
}

// Если это оплата опциональных расходников букинга, то в транзакции есть movement и нет transactionsServices
export const checkIfOptionalConsumablesPayment = (
  transaction: ServerTransactionInBookingType | ServerTransactionType | ServerTransactionByIdType,
) => transaction.bookingId && transaction.movement && transaction.transactionsServices.length === 0

export const getServiceInfoByTransaction = (
  serviceId: number,
  transactions: Array<ServerTransactionInBookingType>,
): {
  servicePaymentTransactionId: number
  isServicePaidByCard: boolean
  isServiceReturned: boolean
} => {
  const serviceInfo = {} as {
    servicePaymentTransactionId: number
    isServicePaidByCard: boolean
    isServiceReturned: boolean
  }

  const transactionsWithService: Array<ServerTransactionInBookingType> = []

  for (let i = 0; i < transactions.length; i++) {
    for (let s = 0; s < transactions[i].transactionsServices.length; s++) {
      if (transactions[i].transactionsServices[s].serviceId === serviceId) {
        if (
          transactions[i].type === TRANSACTION_TYPES.payment.id ||
          transactions[i].type === TRANSACTION_TYPES.paymentInCredit.id
        ) {
          serviceInfo.servicePaymentTransactionId = transactions[i].id
          serviceInfo.isServicePaidByCard = Boolean(transactions[i].cardId)
          transactionsWithService.push(transactions[i])
          serviceInfo.isServiceReturned = !getIsSomeServicesPaid(
            [{ id: transactions[i].transactionsServices[s].serviceId }],
            transactions,
          )
        } else transactionsWithService.push(transactions[i])
      }
    }
  }

  return serviceInfo
}

export const useGetIsBookingInPast = (
  bookingStartDate: Date | undefined,
  timezone: string | undefined,
) => {
  const startOfCurrentDay = useStartOfCurrentDay(timezone)

  return bookingStartDate && bookingStartDate < startOfCurrentDay
}

export const getIsDayOfBookingStartDateChanged = (oldStartDate: Date, newStartDate: Date) =>
  oldStartDate.getFullYear() !== newStartDate.getFullYear() ||
  oldStartDate.getMonth() !== newStartDate.getMonth() ||
  oldStartDate.getDate() !== newStartDate.getDate()

export const getIsBookingRecurring = <T extends { recurringBooking: { id: number } | null }>(
  item: T,
) => item.recurringBooking !== null

export const getIsBookingDone = <T extends { status: number | null }>(booking: T | undefined) =>
  booking?.status === BOOKING_STATUSES.done

export const getIsBookingCanceled = <T extends { canceledDate: Date | null }>(
  booking: T | undefined,
) => Boolean(booking?.canceledDate)

export const getRecurringBookingIdsThatCanBeCanceled = <
  T extends {
    id: number
    transactions: ServerTransactionInBookingType[]
    status: number | null
    canceledDate: Date | null
  },
>(
  recurringBookings: T[],
) => {
  const filteredRecurringBookings = getBookingsThatCanBeCanceled(recurringBookings)

  return filteredRecurringBookings.map(({ id }) => id)
}

export const getBookingsThatCanBeCanceled = <
  T extends {
    id: number
    transactions: ServerTransactionInBookingType[]
    status: number | null
    canceledDate: Date | null
  },
>(
  bookings: T[],
) =>
  bookings.filter(recurringBooking => {
    const isSomeServicesPaid = getIsSomeServicesPaid([], recurringBooking.transactions)
    const isSomeProductsPaid = getIsSomeProductsPaid([], recurringBooking.transactions)

    const isDone = getIsBookingDone(recurringBooking)
    const isCanceled = getIsBookingCanceled(recurringBooking)

    return !isSomeServicesPaid && !isSomeProductsPaid && !isDone && !isCanceled
  })

export const getRecurringBookingThatCanBeEdited = (
  recurringBookings: ServerRecurringBookingByIdType[],
) =>
  recurringBookings.filter(recurringBooking => {
    const isDone = getIsBookingDone(recurringBooking)

    return !isDone
  })

export const checkIsSalaryIssuedByBooking = (booking: BookingUnion | undefined) =>
  booking
    ? booking.salaryIssues.some(salaryIssue => salaryIssue.salaryTransactionId !== null)
    : false

export const useDisabledStatusReasons = ({
  timezone,
  bookingById,
  myPermissions,
}: {
  timezone: string | undefined
  bookingById?: BookingUnion
  myPermissions: string[] | undefined
}) => {
  const { t } = useTranslation()

  const isSalaryIssued = checkIsSalaryIssuedByBooking(bookingById)
  const isBookingInPast = useGetIsBookingInPast(bookingById?.startDate, timezone)

  let disabledSetStatusReason: string | undefined
  let disabledSetStatus = false

  if (!myPermissions?.includes(permissions.bookingStatus.set)) {
    disabledSetStatusReason = t('disabledBookingStatusReasons.set')
    disabledSetStatus = true
  }
  if (!myPermissions?.includes(permissions.booking.editPast) && isBookingInPast) {
    disabledSetStatusReason = t('disabledBookingStatusReasons.editPast')
    disabledSetStatus = true
  }

  let disabledUnsetStatusReason: string | undefined
  let disabledUnsetStatus = false

  if (!myPermissions?.includes(permissions.bookingStatus.unset)) {
    disabledUnsetStatusReason = t('disabledBookingStatusReasons.unset')
    disabledUnsetStatus = true
  } else if (isSalaryIssued) {
    disabledUnsetStatusReason = t('disabledBookingStatusReasons.salary')
    disabledUnsetStatus = true
  }
  if (!myPermissions?.includes(permissions.booking.editPast) && isBookingInPast) {
    disabledUnsetStatusReason = t('disabledBookingStatusReasons.editPast')
    disabledUnsetStatus = true
  }
  if (bookingById?.canceledDate) {
    disabledUnsetStatusReason = t('disabledBookingStatusReasons.canceled')
    disabledSetStatusReason = t('disabledBookingStatusReasons.canceled')
    disabledUnsetStatus = true
    disabledSetStatus = true
  }

  return {
    disabledSetStatusReason,
    disabledSetStatus,
    disabledUnsetStatusReason,
    disabledUnsetStatus,
  }
}

export const getBookedServicesQuantity = (bookings: ServerBookingAsClientType[] | undefined) => {
  const result: Record<number, number> = {}

  if (!bookings) return result

  for (const booking of bookings) {
    if (booking.isGroupBooking) {
      if (booking.service) {
        if (result[booking.service.id]) {
          result[booking.service.id] = result[booking.service.id] + 1
        } else {
          result[booking.service.id] = 1
        }
      }
    } else {
      for (const bookingService of booking.bookingServices) {
        if (result[bookingService.service.id]) {
          result[bookingService.service.id] = result[bookingService.service.id] + 1
        } else {
          result[bookingService.service.id] = 1
        }
      }
    }
  }

  return result
}

export const getServiceIdsFromBooking = <
  T extends {
    isGroupBooking: boolean
    bookingServices: Array<{
      service: {
        id: number
      }
    }>
    service: {
      id: number
    } | null
  },
>(
  booking: T | undefined,
) => {
  const serviceIds: number[] = []

  if (!booking) return serviceIds
  else {
    if (booking.isGroupBooking) {
      if (typeof booking.service?.id === 'number') serviceIds.push(booking.service.id)
    } else {
      for (const service of booking.bookingServices) {
        serviceIds.push(service.service.id)
      }
    }
  }
  return serviceIds
}

export const calcBookingOptionalConsumablesTotalPrice = (
  bookingProducts: { quantity: number; product: { price: number; unit: number } }[],
) =>
  bookingProducts.reduce(
    (acc, bookingProduct) =>
      acc +
      bookingProduct.product.price *
        convertUnitValueFromServer(bookingProduct.quantity, bookingProduct.product.unit),
    0,
  )

export const getBookingTotalSum = (booking: ServerBookingAsClientType | undefined) => {
  if (!booking) return 0
  else {
    const servicePrice = booking.isGroupBooking
      ? // в групповом букинге только одна услуга
        booking.service?.price ?? 0
      : booking.bookingServices.reduce(
          (acc, value) => acc + (value.service ? value.service.price : 0),
          0,
        )

    const productsPrice = calcBookingOptionalConsumablesTotalPrice(booking.bookingProducts)

    return servicePrice + productsPrice
  }
}
