import { useEffect, useState } from 'react'
import { useAddLeadHistory, useFetchMarketingCampaignsIdsAnonymously } from '@expane/data'
import { setCookie } from '../cookies'
import { ConfigType, COOKIE_MAX_AGE, getCookieParams, getLeadIdFromCookie } from './'
import { useParseUTMSearchParams } from './useParseUTMSearchParams'
import { generateUUIDv4 } from '../utils'

/*
  Цей хук треба вставляти в root роутера
  Якщо в проєкті налаштовані редіректи, то треба хув вставляти так, щоб він спрацював до редіректа
 */
export const useUtmTracking = (config: ConfigType) => {
  const [done, setDone] = useState(false)

  const { data: marketingCampaignsIds } = useFetchMarketingCampaignsIdsAnonymously()
  const { mutateAsync: addLeadHistory } = useAddLeadHistory()

  const parsedUtmTags = useParseUTMSearchParams()

  useEffect(() => {
    if (done) return
    if (!parsedUtmTags || !marketingCampaignsIds) return

    // Якщо невірна маркетингова компанія, то це невірно сформоване посилання і ми його не обробляємо
    if (!marketingCampaignsIds.includes(parsedUtmTags.utmCampaign)) return

    const cookieParams = getCookieParams(config)

    const parsedCookieLeadId = getLeadIdFromCookie(config)
    // Якщо були вже записи історії, то оновлюємо історію
    if (parsedCookieLeadId) {
      setCookie({
        key: cookieParams.key,
        value: parsedCookieLeadId,
        domain: cookieParams.domain,
        maxAge: COOKIE_MAX_AGE,
      })

      addLeadHistory({
        leadId: parsedCookieLeadId,
        utmCampaign: parsedUtmTags.utmCampaign,
        utmTags: parsedUtmTags.utmTags,
        url: parsedUtmTags.href,
      })

      setDone(true)
      return
    }

    // Якщо перший раз, то генеруємо id та записуємо історію
    const newId = generateUUIDv4()

    setCookie({
      key: cookieParams.key,
      value: newId,
      domain: cookieParams.domain,
      maxAge: COOKIE_MAX_AGE,
    })

    addLeadHistory({
      leadId: newId,
      utmCampaign: parsedUtmTags.utmCampaign,
      utmTags: parsedUtmTags.utmTags,
      url: parsedUtmTags.href,
    })

    setDone(true)
  }, [addLeadHistory, config, done, marketingCampaignsIds, parsedUtmTags])
}
