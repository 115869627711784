import {
  DataForCheckboxReturnReceipt,
  ServerAccountType,
  ServerTransactionByIdType,
  TRANSACTION_TYPES,
  useCheckboxCreateReturnReceipt,
  useCreateTransactionFromAccount,
  useCreateTransactionRefundWithoutFromAccount,
  useDeactivateClientCards,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import {
  handleUpdateCreateReceiptResponse,
  transformTransactionSubscriptionFormValuesForReturnReceipt,
} from '@expane/logic/payment/checkbox'
import { checkIsTransactionPaymentInCredit } from '@expane/logic/transaction'
import { TransactionSubscriptionFormValues } from '@expane/logic/transaction/refund'
import { CloseButton, Dialog, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { generateUUIDv4 } from '@expane/web-logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, SubmitHandler, UseFormHandleSubmit, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'

interface TransactionRefundDialogFooterProps {
  control: Control<TransactionSubscriptionFormValues>
  handleSubmit: UseFormHandleSubmit<TransactionSubscriptionFormValues>
  transactionById: ServerTransactionByIdType
  onSuccess: () => void
  closeDialog: () => void
  totalRefundPrice: number
  isSubmitting: boolean
  isCreateRefundButton: boolean
  accounts: ServerAccountType[]
}

export const TransactionSubscriptionRefundDialogFooter: FC<TransactionRefundDialogFooterProps> =
  observer(
    ({
      control,
      handleSubmit,
      transactionById,
      closeDialog,
      onSuccess,
      totalRefundPrice,
      isSubmitting,
      isCreateRefundButton,
      accounts,
    }) => {
      const branchId = store.branch.branchId

      const { mutateAsync: transactionFromAccount } = useCreateTransactionFromAccount()
      const { mutateAsync: transactionRefundToClientBalance } =
        useCreateTransactionRefundWithoutFromAccount()
      const { mutateAsync: deactivateCards } = useDeactivateClientCards()
      const { mutateAsync: createReturnReceipt } = useCheckboxCreateReturnReceipt()

      const { getModuleSetting } = useBusinessModulesSettings()
      const isCheckboxEnabled = getModuleSetting('checkbox')

      const [openSnackBar] = useSnackbar()
      const { t } = useTranslation()
      const convertToMoney = useConvertNumberToMoneyCode({ branchId })

      const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

      const watchedRefundToClientAccount = useWatch({ control, name: 'refundToClientAccount' })

      const mutateRefundTransaction: SubmitHandler<
        TransactionSubscriptionFormValues
      > = async data => {
        const { item, refundToClientAccount, fromAccountId } = data

        if (!branchId) {
          openSnackBar(t('transaction.cardDeactivationFailed'), 'error', 3000)
          closeDialog()

          return
        }

        let result: { insertTransaction?: { id?: number } } | undefined

        const isTransactionInCredit = checkIsTransactionPaymentInCredit(transactionById)

        try {
          if (refundToClientAccount || isTransactionInCredit) {
            result = await transactionRefundToClientBalance({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: isTransactionInCredit
                ? TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id
                : TRANSACTION_TYPES.refund.variations.refundToClientAccount.id,
              amount: totalRefundPrice,
              clientId: transactionById.client?.id ?? null,
              parentId: transactionById.id,
              transactionsCards: {
                data: [{ cardId: item.id, price: item.price }],
              },
              branchId,
            })
          } else {
            let dataForCheckboxReturnReceipt: DataForCheckboxReturnReceipt | null = null
            if (isCheckboxEnabled) {
              dataForCheckboxReturnReceipt =
                transformTransactionSubscriptionFormValuesForReturnReceipt({
                  data,
                  totalSum: totalRefundPrice,
                  accounts,
                  transaction: transactionById,
                  generateUUIDv4,
                })
            }

            let receiptId: string | undefined
            if (dataForCheckboxReturnReceipt) {
              const result = await createReturnReceipt(dataForCheckboxReturnReceipt)

              receiptId = handleUpdateCreateReceiptResponse({
                t,
                onError: message => openSnackBar(message, 'error', 3000),
                createReceiptResult: result.checkboxCreateReceipt,
              })

              if (!receiptId) return
            }

            result = await transactionFromAccount({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: TRANSACTION_TYPES.refund.variations.refundFromAccount.id,
              amount: totalRefundPrice,
              fromAccountId,
              clientId: transactionById.client?.id,
              parentId: transactionById.id,
              transactionsCards: {
                data: [
                  {
                    cardId: item.id,
                    price: item.price,
                  },
                ],
              },
              branchId,
              transactionReceipts:
                receiptId && dataForCheckboxReturnReceipt?.softwarePOSId
                  ? {
                      data: [
                        { receiptId, softwarePOSId: dataForCheckboxReturnReceipt?.softwarePOSId },
                      ],
                    }
                  : undefined,
            })
          }
          // деактивируем возвращенные карты
          if (result?.insertTransaction?.id) {
            const deactivateCardResult = await deactivateCards([item.id])
            if (!deactivateCardResult?.updateCards)
              openSnackBar(t('transaction.cardDeactivationFailed'), 'error', 3000)
          }

          openSnackBar(t('transaction.refundCompletedSuccessfully'), 'success')
        } catch (error) {
          if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
          else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
          else {
            openSnackBar(t('transaction.refundFailed'), 'error')
            reportError(error, 'error', {
              item,
            })
          }
        }

        closeDialog()
        onSuccess()
      }

      const handleOnRefund = () =>
        showConfirmation({
          title: t('transaction.creatingRefund'),
          description: `${t('transaction.doYouWantToCreateRefund')} ${convertToMoney(
            totalRefundPrice,
          )} ${watchedRefundToClientAccount ? t('transaction.onClientAccount') : ''}?`,
          onConfirm: () => handleSubmit(mutateRefundTransaction)(),
        })

      return (
        <>
          <Dialog.Footer>
            {isCreateRefundButton && (
              <SaveButton
                onClick={handleOnRefund}
                spinner={isSubmitting}
                disabled={isSubmitting || totalRefundPrice === 0}
                isCreate
              />
            )}

            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
          {confirmationModal}
        </>
      )
    },
  )
