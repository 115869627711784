import { useEffect, useMemo, useState } from 'react'

export const useParseUTMSearchParams = () => {
  const [utmCampaign, setUtmCampaign] = useState<number | null>(null)
  const [utmTags, setUtmTags] = useState<Record<string, string>>({})
  const [done, setDone] = useState<boolean>(false)

  const href = useMemo(() => window.location.href, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    for (const [key, value] of searchParams) {
      if (!key.startsWith('utm_')) continue

      if (key === 'utm_campaign') {
        const utmCampaignId = Number(value)
        if (isNaN(utmCampaignId)) break

        setUtmCampaign(utmCampaignId)
        continue
      }

      setUtmTags(prevState => ({ ...prevState, [key]: value }))
    }

    setDone(true)
  }, [])

  return utmCampaign && done
    ? {
        utmCampaign,
        utmTags,
        href,
      }
    : null
}
