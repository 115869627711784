import { transformPersonName } from '@expane/logic/utils'
import { SelectDropDownItem } from '@expane/ui'
import { TFunction } from 'i18next'
import { formatPhoneNumber } from 'logic/ui'

export const transformPersonsForSelect = <
  T extends {
    id: number
    firstName: string
    lastName?: string | null
    phone?: string | null
  },
>(
  persons: T[],
  withPhone?: boolean,
): SelectDropDownItem[] =>
  persons.map(person => ({
    ...person,
    name: transformPersonName(person),
    phoneItem: withPhone && person.phone ? formatPhoneNumber(person.phone) : undefined,
  }))

const convertNumberToFractionalMoneyWithoutSymbol = (value: number) =>
  new Intl.NumberFormat('uk-UA', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value)
export const convertNumberToFractionalMoneyString = (value: number) => {
  return convertNumberToFractionalMoneyWithoutSymbol(value).replace(/\s/g, '').replace(/,/g, '.')
}

export const translateTableTitles = <
  T extends {
    title: string
    hint?: string | null
  },
>(
  titles: T[],
  t: TFunction,
) =>
  titles.map(({ title, hint, ...rest }) => ({
    ...rest,
    title: t(title),
    hint: hint ? t(hint) : undefined,
  }))

export const translateData = <T extends { name: string; hint?: string }>(
  items: T[],
  t: TFunction,
) =>
  items.map(({ name, hint, ...rest }) => ({
    ...rest,
    name: t(name),
    hint: hint ? t(hint) : undefined,
  }))

export const filterByBranchId = <T extends { branchId: number }>(
  items: T[],
  branchId: number,
): T[] => items.filter(item => item.branchId === branchId)
