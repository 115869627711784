import { ServerLanguages, useBootstrapBusiness, useFetchMyBusinesses } from '@expane/data'
import { getCurrencyCodeById } from '@expane/logic/currency'
import { getDefaultBusinessModuleSettingsValues, ModuleSettingsForm } from '@expane/logic/modules'
import { DEFAULT_TIMEZONE, getTimezoneById } from '@expane/date'
import { useWebPersistedState } from '@expane/web-logic/useWebPersistedState'
import { Paper, Spinner } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { observer } from 'mobx-react-lite'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import {
  MAIN_INFO_STORAGE_KEY,
  MODULES_STORAGE_KEY,
  stepStyle,
} from 'pages/CreateBusinessPage/logic'
import { useOpenChooseBusinessCreationProcessDialog } from 'pages/CreateBusinessPage/QuickSteps/MainInfoStep/ChooseBusinessCreationProcessDialog'
import { ModulesSettingsTable } from 'pages/SettingsPage/BusinessModulesSettings/ModulesSettingsTable'
import React, { FC, useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CreateBusinessType } from 'routes/logic'
import { reportError } from 'services/api'
import { store } from 'store'
import { MainInfoFormValues } from '@expane/logic/business'
import { reloadApp } from 'logic/app'
import { getLeadIdFromCookie, removeLeadCookie } from '@expane/web-logic/leadGeneration'
import { config } from 'config'

export const ModulesStep: FC = observer(() => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { data: myBusinesses } = useFetchMyBusinesses(store.me.isAuthorised)
  const { mutateAsync: bootstrapBusiness, isLoading: isLoadingBootstrappingBusiness } =
    useBootstrapBusiness()

  const { storage: mainInfoStorage, clearPersistedStorage: clearPersistedMainInfoStorage } =
    useWebPersistedState<MainInfoFormValues>(MAIN_INFO_STORAGE_KEY, reportError)

  const {
    storage: modulesStorage,
    updateStorage: updateModulesStorage,
    clearPersistedStorage: clearPersistedModulesStorage,
  } = useWebPersistedState<ModuleSettingsForm>(MODULES_STORAGE_KEY, reportError)

  const { proceedToNextStep, changeCreateBusinessType } = useCreateBusinessSteps()
  const { chooseBusinessCreationProcess, chooseBusinessCreationProcessDialog } =
    useOpenChooseBusinessCreationProcessDialog()

  const [openSnackBar] = useSnackbar()

  const { control, handleSubmit } = useForm<ModuleSettingsForm>({
    defaultValues: getDefaultBusinessModuleSettingsValues(modulesStorage),
  })

  // TODO: Move to event handler
  useEffect(() => {
    if (myBusinesses?.find(business => business.ownerId === store.me.employeeId)) {
      clearPersistedMainInfoStorage()
      clearPersistedModulesStorage()
      proceedToNextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearPersistedMainInfoStorage,
    clearPersistedModulesStorage,
    myBusinesses,
    proceedToNextStep,
    store.me.employeeId,
  ])

  // For saving data in storage
  const watchedManagingEmployee = useWatch({ control, name: 'managingEmployee' })
  const watchedBranches = useWatch({ control, name: 'branches' })
  const watchedSubscriptions = useWatch({ control, name: 'subscriptions' })
  const watchedGiftCards = useWatch({ control, name: 'giftCards' })
  const watchedServicesForSale = useWatch({ control, name: 'servicesForSale' })
  const watchedProductsForSale = useWatch({ control, name: 'productsForSale' })
  const watchedConsumables = useWatch({ control, name: 'consumables' })
  const watchedCheckbox = useWatch({ control, name: 'checkbox' })
  const watchedPayment = useWatch({ control, name: 'payment' })

  useEffect(() => {
    updateModulesStorage({
      managingEmployee: watchedManagingEmployee,
      branches: watchedBranches,
      subscriptions: watchedSubscriptions,
      giftCards: watchedGiftCards,
      servicesForSale: watchedServicesForSale,
      productsForSale: watchedProductsForSale,
      consumables: watchedConsumables,
      checkbox: watchedCheckbox,
      payment: watchedPayment,
    })
  }, [
    watchedManagingEmployee,
    watchedBranches,
    watchedSubscriptions,
    watchedGiftCards,
    watchedServicesForSale,
    watchedProductsForSale,
    watchedConsumables,
    watchedCheckbox,
    updateModulesStorage,
    watchedPayment,
  ])

  const submitBootstrapBusiness: SubmitHandler<ModuleSettingsForm> = async modules => {
    if (!mainInfoStorage) {
      openSnackBar(t('submitError'), 'error')
      return
    }

    const {
      businessName,
      ownerFirstName,
      ownerLastName,
      ownerPhone,
      photo,
      planId,
      timezoneId,
      currencyId,
      promoCode,
      branchPhone,
      branchAddress,
    } = mainInfoStorage

    if (!businessName || !ownerFirstName || !timezoneId || !currencyId || !planId) {
      openSnackBar(t('submitError'), 'error')
      return
    }

    try {
      const leadId = getLeadIdFromCookie(config)

      const business = await bootstrapBusiness({
        businessName,
        ownerFirstName,
        ownerLastName: ownerLastName ?? '',
        ownerPhone: ownerPhone ?? null,
        lang: language as ServerLanguages,
        photo,
        timezone: getTimezoneById(timezoneId)?.name ?? DEFAULT_TIMEZONE,
        planId,
        currency: getCurrencyCodeById(currencyId),
        modulesSettings: modules,
        promoCode: promoCode?.id,
        branchPhone,
        branchAddress,
        leadId,
      })

      const businessId = business?.createBusiness?.businessId
      if (businessId) {
        reloadApp()
        removeLeadCookie(config)
      } else openSnackBar(t('submitError'), 'error')
    } catch (e) {
      reportError(e as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  const handleNextBtn = () => {
    chooseBusinessCreationProcess({
      onCreateBusiness: handleSubmit(submitBootstrapBusiness),
      onCreateFullProcessBusiness: () => {
        changeCreateBusinessType(CreateBusinessType.extended)
        handleSubmit(submitBootstrapBusiness)()
      },
    })
  }

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' flex flex-col h-144 w-160'}>
        {isLoadingBootstrappingBusiness ? (
          <Spinner expandCentered />
        ) : (
          <ModulesSettingsTable control={control} />
        )}
      </Paper>

      <ProceedToNextStep className="mt-2" onClick={handleNextBtn} />

      {chooseBusinessCreationProcessDialog}
    </div>
  )
})
