import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerBusinessLeadInsertInput } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { BUSINESS_LEADS_QUERY_KEY } from './queryKeys'

export function useMutateLeads() {
  return useMutation(
    (
      businessLeadInsertInputs: ServerBusinessLeadInsertInput[],
    ): Promise<{
      insertBusinessLeads: {
        affectedRows: number
        returning: {
          id: number
          name: string
        }[]
      }
    }> => {
      return request(
        gql`
          mutation ($businessLeadInsertInputs: [businessLead_insert_input!]!) {
            insertBusinessLeads(
              objects: $businessLeadInsertInputs
              on_conflict: { constraint: businessLead_pkey, update_columns: [name] }
            ) {
              affectedRows: affected_rows
              returning {
                id
                name
              }
            }
          }
        `,
        { businessLeadInsertInputs },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([BUSINESS_LEADS_QUERY_KEY])
      },
    },
  )
}
