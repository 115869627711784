import {
  BookingUnion,
  PaymentTransaction,
  ServerAccountType,
  ServerCardType,
  ServerMovementProductUpdates,
  TRANSACTION_TYPES,
} from '@expane/data'
import {
  CLIENT_ACCOUNT_ID,
  ClientDepositTransactionInputDto,
  generateOneAccountDepositTransactionsDto,
  generateSeveralAccountDepositTransactionsDto,
  PAYMENT_OPTIONS,
  PaymentOption,
  PaymentToAccount,
} from '../'
import {
  calcServiceTotalSum,
  DEFAULT_SERVICE_QUANTITY,
  formServicePaymentTransaction,
  getServicesWithDiscount,
  ServiceDto,
} from './services'
import {
  calcConsumableTotalSum,
  ConsumableDto,
  formConsumablePaymentTransactions,
} from './consumables'
import { checkIsCardActivated } from '../../cards'
import { checkIsRefundTransaction, checkIsTransactionPayment } from '../../finances/filters'
import { checkIsTransactionPaymentInCredit } from '../../transaction'
import { calcTotalPrice } from '../../utils'

export type BookingPaymentData = {
  servicesDto: ServiceDto[]
  consumablesDto: ConsumableDto[]
  paymentOption: PaymentOption
  paymentAmount: string
  accountId: number
  keepChangeOnClientAccount: boolean
  paymentToAccounts: PaymentToAccount[]
}

export const generateDepositTransactions = (
  data: BookingPaymentData,
  standingAccounts: ServerAccountType[],
  clientId: number,
  branchId: number,
) => {
  let depositTransactionsDto: ClientDepositTransactionInputDto[] = []

  const totalServicesSum = calcServiceTotalSum(data.servicesDto)
  const totalConsumablesSum = calcConsumableTotalSum(data.consumablesDto)

  const totalInvoiceSum = totalConsumablesSum + totalServicesSum

  const needToDepositToOneAccount =
    data.paymentOption.id === PAYMENT_OPTIONS[0].id &&
    totalInvoiceSum > 0 &&
    data.accountId !== CLIENT_ACCOUNT_ID

  if (needToDepositToOneAccount) {
    const account = standingAccounts.find(acc => acc.id === data.accountId)

    depositTransactionsDto = generateOneAccountDepositTransactionsDto({
      accountId: data.accountId,
      amount: data.keepChangeOnClientAccount ? Number(data.paymentAmount) : totalInvoiceSum,
      commission: account?.commission ?? null,
      clientId,
      branchId,
    })
  }

  const needToDepositToSeveralAccounts =
    data.paymentOption.id === PAYMENT_OPTIONS[1].id && totalInvoiceSum > 0

  if (needToDepositToSeveralAccounts) {
    depositTransactionsDto = generateSeveralAccountDepositTransactionsDto({
      paymentToAccounts: data.paymentToAccounts,
      standingAccounts,
      clientId,
      branchId,
    })
  }

  return depositTransactionsDto
}

export const getItemsForSale = (dto: {
  debt: number
  bookings: BookingUnion[] | undefined
  servicesDto: ServiceDto[]
  optionalConsumables: ConsumableDto[]
  clientId: number
  defaultBranchStorageId: number
}): {
  saleTransactions: PaymentTransaction[]
  movementProductsUpdates: ServerMovementProductUpdates[]
} => {
  const { bookings, servicesDto, optionalConsumables, clientId, debt } = dto

  // On each payment transaction we gonna check how much money need to be paid in credit
  // And then decrease debt to keep the actual number for other transactions
  let mutableDebt = debt

  const saleTransactions: PaymentTransaction[] = []
  const movementProductsUpdates: ServerMovementProductUpdates[] = []

  const servicesNotBySubscription = servicesDto.filter(item => !item.subscriptionId)

  bookings?.forEach(booking => {
    const servicesInBooking = servicesNotBySubscription.filter(serviceDto => {
      return Boolean(serviceDto.bookingId === booking.id)
    })

    if (servicesInBooking.length) {
      const servicesDto = servicesInBooking.map(el => ({
        ...el,
        quantity: DEFAULT_SERVICE_QUANTITY,
      }))
      // количество услуги в одном букинге === 1

      const servicesForTransactions = getServicesWithDiscount(servicesDto)

      // стоимость услуг
      const totalPriceForServices = calcTotalPrice(servicesForTransactions)

      saleTransactions.push(
        formServicePaymentTransaction({
          amount: totalPriceForServices,
          bookingId: booking.id,
          clientId,
          servicesForTransactions,
          employeeId: booking.employee?.id,
          debt: mutableDebt,
          branchId: booking.branchId,
        }),
      )
      if (mutableDebt > 0) mutableDebt = mutableDebt - totalPriceForServices
    }
    const filteredOptionalConsumables = optionalConsumables.filter(
      ({ bookingId }) => bookingId === booking.id,
    )

    if (filteredOptionalConsumables.length > 0) {
      const totalOptionalConsumablesPrice = calcConsumableTotalSum(filteredOptionalConsumables)

      const { transactions, consumablesUpdates } = formConsumablePaymentTransactions({
        booking,
        clientId,
        employeeId: booking.employee?.id,
        filteredOptionalConsumables,
        debt: mutableDebt,
        branchId: booking.branchId,
      })

      saleTransactions.push(...transactions)
      movementProductsUpdates.push(...consumablesUpdates)
      if (mutableDebt > 0) mutableDebt = mutableDebt - totalOptionalConsumablesPrice
    }
  })

  return { saleTransactions, movementProductsUpdates }
}

export const generatePaymentTransactionsForSubscriptions = ({
  bookings,
  clientId,
  servicesDto,
  subscriptions,
}: {
  bookings: BookingUnion[]
  clientId: number
  servicesDto: ServiceDto[]
  subscriptions: ServerCardType[]
}): {
  transactionsBySubscriptions: PaymentTransaction[]
  subscriptionIdsToActivate: number[]
} => {
  const servicesBySubscription = servicesDto.filter(service => Boolean(service.subscriptionId))
  if (servicesBySubscription.length === 0)
    return { transactionsBySubscriptions: [], subscriptionIdsToActivate: [] }

  const result: PaymentTransaction[] = []
  const usedSubscriptions: Record<number, { id: number; activatedAt: Date | null }> = {}

  for (const booking of bookings) {
    const servicesInBooking = servicesBySubscription.filter(
      service => service.bookingId === booking.id,
    )
    const servicesWithPricesBySubscription = servicesInBooking.map(service => {
      const subscriptionById = subscriptions.find(
        subscription => subscription.id === service.subscriptionId,
      )
      if (subscriptionById === undefined) throw new Error('Subscription was not found')
      if (!usedSubscriptions[subscriptionById.id]) {
        usedSubscriptions[subscriptionById.id] = {
          id: subscriptionById.id,
          activatedAt: subscriptionById.activatedAt ?? null,
        }
      }

      const priceBySubscription = subscriptionById?.cardTemplate.cardTemplateServices.find(
        ({ serviceId }) => service.serviceId === serviceId,
      )?.servicePrice

      if (priceBySubscription === undefined) throw new Error('Price by subscription was not found')

      return { ...service, price: priceBySubscription }
    })

    const totalPriceForServices = calcTotalPrice(servicesWithPricesBySubscription)

    const servicesData = booking.isGroupBooking
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [booking.service!]
      : booking.bookingServices.map(({ service }) => service)

    servicesInBooking.forEach(serviceDto => {
      const serviceData = servicesData.find(serviceData => serviceData.id === serviceDto.serviceId)
      if (!serviceData) throw new Error('serviceData was not found! Id:' + serviceDto.serviceId)

      result.push({
        amount: totalPriceForServices,
        type: TRANSACTION_TYPES.payment.id,
        typeVariation: TRANSACTION_TYPES.payment.variations.service.id,
        bookingId: booking.id,
        clientId,
        employeeId: booking.employee?.id,
        cardId: serviceDto.subscriptionId,
        transactionsServices: {
          data: [
            {
              serviceId: serviceDto.serviceId,
              price:
                servicesWithPricesBySubscription.find(
                  service => service.serviceId === serviceDto.serviceId,
                )?.price ?? 0,
              costPrice: serviceDto.costPrice,
            },
          ],
        },
        branchId: booking.branchId,
      })
    })
  }

  const subscriptionIdsToActivate = Object.values(usedSubscriptions)
    .filter(subscription => !checkIsCardActivated(subscription.activatedAt))
    .map(subscription => subscription.id)

  return {
    transactionsBySubscriptions: result,
    subscriptionIdsToActivate,
  }
}

export const getNotRefundedPaymentTransactionsFromBooking = (booking: BookingUnion) => {
  const refundedTransactionIdsHash: { [x: string]: boolean } = {}
  for (const transaction of booking.transactions) {
    if (transaction.parentId && checkIsRefundTransaction(transaction))
      refundedTransactionIdsHash[transaction.parentId] = true
  }
  const notRefundedPaymentTransactions = booking.transactions.filter(
    transaction =>
      (checkIsTransactionPayment(transaction) || checkIsTransactionPaymentInCredit(transaction)) &&
      // check that the transaction is not refunded
      !refundedTransactionIdsHash[transaction.id],
  )

  return notRefundedPaymentTransactions
}

export type Consumable = {
  bookingProductId: number | undefined
  productId: number | undefined
  quantity: string
  price?: number
}

type Movement = NonNullable<BookingUnion['transactions'][0]['movement']>
type MovementProduct = Movement['movementProducts'][0]
export const getDefaultConsumablesForBookingForm = (
  isCreate: boolean,
  booking: BookingUnion | undefined,
): Consumable[] => {
  if (isCreate) return []
  if (!booking) throw new Error('Booking must be present in edit dialog')

  const notRefundedPaymentTransactions = getNotRefundedPaymentTransactionsFromBooking(booking)
  const movementProducts = notRefundedPaymentTransactions.reduce(
    (movementProducts, transaction) => {
      if (!transaction.movement) return movementProducts

      movementProducts.push(...transaction.movement.movementProducts)

      return movementProducts
    },
    [] as MovementProduct[],
  )

  return booking.bookingProducts.map(({ id, productId, quantity, product }) => {
    const price = movementProducts.find(mP => mP.productId === productId)?.price ?? product.price
    return {
      bookingProductId: id,
      productId,
      quantity: quantity.toString(),
      price,
    }
  })
}
