export const getCookie = (key: string) => {
  const allCookies = document.cookie.split(';')

  return allCookies.map(cookie => cookie.trim()).find(cookie => cookie.startsWith(key))
}

export const setCookie = (dto: { key: string; value: string; domain: string; maxAge: number }) => {
  const cookie: string[] = []

  cookie.push(`${dto.key}=${encodeURIComponent(dto.value)}`)
  cookie.push(`domain=${dto.domain}`)
  cookie.push(`max-age=${dto.maxAge}`)
  // Для того, щоб cookie було доступно по всьому сайту
  cookie.push('path=/')

  document.cookie = cookie.join(';')
}
