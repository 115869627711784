import {
  ServerTransactionInBookingType,
  TRANSACTION_TYPES,
  useFetchAccounts,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { checkWithdrawPossibility } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { findById } from '@expane/logic/utils'
import { Checkbox, CloseButton, Dialog, InputLabel, Modal } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { SubmitButton } from 'widgets/Buttons'
import { TransactionDialog } from 'widgets/TransactionDialog'
import { TransactionsDialogList } from 'widgets/TransactionsDialog/List'
import {
  checkAreEveryTransactionPaymentInCredit,
  checkIsSomeTransactionPaymentInCredit,
} from '@expane/logic/transaction'
import {
  SomeTransactionInCreditExplanation,
  TransactionInCreditExplanation,
} from 'widgets/TransactionInCreditExplanation'

interface BookingCancelPaymentDialogProps {
  transactions: ServerTransactionInBookingType[]
  control: Control<BookingCancelDialogForm>
  onConfirm: SubmitHandler<BookingCancelDialogForm>
  setValue: UseFormSetValue<BookingCancelDialogForm>
  handleSubmit: UseFormHandleSubmit<BookingCancelDialogForm>
  closeCancelPaymentBooking: () => void
  isSubmitting: boolean
}

export const BookingCancelPaymentDialog: FC<BookingCancelPaymentDialogProps> = observer(
  ({
    transactions,
    control,
    onConfirm,
    setValue,
    handleSubmit,
    closeCancelPaymentBooking,
    isSubmitting,
  }: BookingCancelPaymentDialogProps) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

    const { data: accounts } = useFetchAccounts(branchId)
    const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)

    const { getModuleSetting } = useBusinessModulesSettings()
    const isCheckboxEnabled = getModuleSetting('checkbox')

    const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
      useOpenDialog(TransactionDialog)

    const watchAccountId = useWatch({ control, name: 'accountId' })
    const watchRefundToClientAccount = useWatch({ control, name: 'refundToClientAccount' })

    const isAllServicesBySubscription = transactions
      ?.filter(
        transaction =>
          transaction.type === TRANSACTION_TYPES.payment.id ||
          transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
      )
      .every(transaction => Boolean(transaction.cardId))

    const paymentTransactionsWithoutRefund = transactions.filter(
      transaction =>
        transaction.type === TRANSACTION_TYPES.payment.id &&
        !transactions.find(refundTransaction => refundTransaction.parentId === transaction.id),
    )

    const inCreditTransactionWithoutRefund = transactions.filter(
      transaction =>
        transaction.type === TRANSACTION_TYPES.paymentInCredit.id &&
        !transactions.find(refundTransaction => refundTransaction.parentId === transaction.id),
    )

    const transactionsWithoutRefund = [
      ...paymentTransactionsWithoutRefund,
      ...inCreditTransactionWithoutRefund,
    ]

    const bookingAmount =
      paymentTransactionsWithoutRefund?.reduce((acc, transaction) => {
        if (transaction.cardId && !watchRefundToClientAccount) return acc

        return acc + transaction.amount
      }, 0) ?? 0

    const isSoftwarePOSAccount = isCheckboxEnabled
      ? Boolean(findById(watchAccountId, accounts)?.accountSoftwarePOS.length)
      : false

    const areEveryTransactionInCredit =
      checkAreEveryTransactionPaymentInCredit(transactionsWithoutRefund)
    const isSomeTransactionInCredit =
      checkIsSomeTransactionPaymentInCredit(transactionsWithoutRefund)

    const isShowAccountSelect = !isAllServicesBySubscription && !areEveryTransactionInCredit

    return (
      <Modal close={closeCancelPaymentBooking}>
        <Dialog>
          <Dialog.Title>{t('cancellationOfBookingPayment.title')}</Dialog.Title>

          <Dialog.Body className="w-192">
            {transactions?.length && (
              <div className="flex flex-col h-48 mb-2">
                <InputLabel label={t('listOfTransactions')} />
                <TransactionsDialogList
                  transactions={transactions}
                  onClickItem={id => editTransactionDialog(id)}
                />
              </div>
            )}

            {!areEveryTransactionInCredit && (
              <div className="mb-2 flex justify-end text-main-color">
                {t('cancellationOfBookingPayment.amountToBeReturned')}:{' '}
                {convertToMoneyCode(bookingAmount)}
              </div>
            )}

            <div className="flex justify-between">
              <div>
                {!areEveryTransactionInCredit && (
                  <Controller
                    control={control}
                    name="refundToClientAccount"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label={t('transaction.refundToClientAccount')}
                        onChange={e => {
                          if (e.target.checked) setValue('accountId', undefined)
                          // При скасуванні вибору цієї опції accountId залишався undefined
                          else setValue('accountId', defaultAccountId)

                          onChange(e)
                        }}
                        checked={value}
                        disabled={isSoftwarePOSAccount}
                        hint={t('transaction.refundToClientBothScenariosHint')}
                        containerClassName="mt-5 mb-1"
                      />
                    )}
                  />
                )}
              </div>

              {isShowAccountSelect && (
                <Controller
                  name="accountId"
                  control={control}
                  rules={{
                    validate: value => {
                      if (value) {
                        return checkWithdrawPossibility({
                          currentAccount: getCurrentAccount(accounts, value),
                          value: bookingAmount,
                        })
                      }
                    },
                  }}
                  render={({ field: { onChange, value }, formState: { errors } }) => (
                    <AccountSelectDropdown
                      label={t('fromAccount')}
                      value={value}
                      onSelectChange={onChange}
                      accounts={accounts ?? []}
                      errorMessage={{
                        isShown: Boolean(errors.accountId),
                        text: t('formError.insufficientFunds'),
                      }}
                      disabled={watchRefundToClientAccount}
                      className="w-1/2 mb-2"
                    />
                  )}
                />
              )}
            </div>

            {areEveryTransactionInCredit && <TransactionInCreditExplanation />}
            {!areEveryTransactionInCredit && isSomeTransactionInCredit && (
              <SomeTransactionInCreditExplanation />
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <SubmitButton
              disabled={
                (!isAllServicesBySubscription &&
                  watchAccountId === undefined &&
                  !watchRefundToClientAccount) ||
                isSubmitting
              }
              spinner={isSubmitting}
              onClick={handleSubmit(onConfirm)}
            />
            <CloseButton onClick={closeCancelPaymentBooking} />
          </Dialog.Footer>
        </Dialog>
        {transactionDialog}
      </Modal>
    )
  },
)

export interface BookingCancelDialogForm {
  accountId?: number
  refundToClientAccount: boolean
}
