import { useGetLeftovers } from '@expane/data'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { checkUnitCanBeFloat, convertUnitValueToServer, getUnitsName } from '@expane/logic/product'
import { DeleteButtonInTable, NumberInput, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { WriteOffMovementProps } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog'
import { ProductDto } from '../ChooseMovementProductsDialog'
import { LeftoverCell } from '../LeftoverCell'

export const WriteOffMovementProductsList: FC<WriteOffMovementProps> = observer(({ control }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const fromStorageId = useWatch({ control, name: 'fromStorageId' })

  const { data: leftoverProducts } = useGetLeftovers(branchId, fromStorageId)

  const { fields, remove } = useFieldArray({ control, name: 'products', keyName: 'generatedId' })
  const isThereOneField = fields.length === 1
  const isStoragePicked = Boolean(fromStorageId)

  const columns = useMemo<ColumnDef<ProductDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('product.name'),
        size: 300,
      },
      {
        id: 'quantity',
        header: t('qty'),
        size: 60,
        cell: data => (
          <Controller
            control={control}
            name={`products.${data.row.index}` as 'products.0'}
            rules={{
              validate: {
                biggerThanZero: value =>
                  value.quantity > 0 &&
                  convertUnitValueToServer(value.quantity, value.unit) <=
                    (leftoverProducts?.[data.row.original.id]?.available ?? 0),
              },
            }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <NumberInput
                onChange={quantity => {
                  onChange({ ...value, quantity })
                }}
                value={value.quantity.toString()}
                errorMessage={{
                  isShown: Boolean(errors.products?.[data.row.index]),
                  text: ' ',
                  reserveIndent: false,
                }}
                height="small"
                containerClassName="w-20"
                allowDecimals={checkUnitCanBeFloat(value.unit)}
              />
            )}
          />
        ),
      },
      {
        id: 'leftover',
        header: () => (
          <span className="w-full text-right">
            {isStoragePicked ? t('inStock') : t('inStocks')}
          </span>
        ),
        cell: data => <WriteOffMovementLeftover control={control} product={data.row.original} />,
        size: 110,
      },
      {
        accessorKey: 'unit',
        header: t('unit.shortName'),
        cell: data => getUnitsName(data.getValue<number>(), t),
        size: 60,
      },
      {
        accessorKey: 'costPrice',
        header: () => <span className="w-full text-right">{t('price')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 100,
      },
      {
        id: 'deleteButton',
        cell: data => (
          <DeleteButtonInTable onClick={() => remove(data.row.index)} disabled={isThereOneField} />
        ),
        size: 30,
        enableResizing: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      control,
      leftoverProducts,
      isStoragePicked,
      convertNumberToMoney,
      isThereOneField,
      remove,
      fromStorageId,
    ],
  )

  return <Table containerClassName="max-h-64" columns={columns} data={fields} />
})

const WriteOffMovementLeftover: FC<WriteOffMovementProps & { product: ProductDto }> = ({
  product,
  control,
}) => {
  const fromStorageId = useWatch({ control, name: 'fromStorageId' })

  return <LeftoverCell product={product} storageId={fromStorageId} />
}
