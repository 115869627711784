import { Control, FieldPath } from 'react-hook-form'
import { ModuleSettingsForm } from '@expane/logic/modules'

export interface ModuleSettingsControl {
  control: Control<ModuleSettingsForm>
}

export interface ModulesSettingsRowProps {
  moduleName: string
  moduleDescr: string
  name: FieldPath<ModuleSettingsForm>
}

export interface ModulesSettingsRowTitleProps {
  title: string
}

type ModulesSettingsRow =
  | (ModulesSettingsRowProps & { type: 'row' })
  | (ModulesSettingsRowTitleProps & { type: 'title' })

export const MODULES_TITLES: { title: string; width?: string }[] = [
  { title: 'modules.name', width: 'w-52' },
  { title: 'description' },
  { title: 'noYes', width: 'w-20' },
]

export const MODULES_SETTINGS_ROWS: ModulesSettingsRow[] = [
  {
    type: 'row',
    name: 'branches',
    moduleName: 'modules.branches.name',
    moduleDescr: 'modules.branches.description',
  },
  {
    type: 'title',
    title: 'employees.name',
  },
  {
    type: 'row',
    name: 'managingEmployee',
    moduleName: 'modules.managingEmployee.name',
    moduleDescr: 'modules.managingEmployee.description',
  },
  {
    type: 'title',
    title: 'services',
  },
  {
    type: 'row',
    name: 'consumables',
    moduleName: 'modules.consumables.name',
    moduleDescr: 'modules.consumables.description',
  },
  {
    type: 'row',
    name: 'subscriptions',
    moduleName: 'modules.subscriptions.name',
    moduleDescr: 'modules.subscriptions.description',
  },
  { type: 'title', title: 'forSaleToClients' },
  {
    type: 'row',
    name: 'giftCards',
    moduleName: 'modules.giftCards.name',
    moduleDescr: 'modules.giftCards.description',
  },
  {
    type: 'row',
    name: 'servicesForSale',
    moduleName: 'modules.servicesForSale.name',
    moduleDescr: 'modules.servicesForSale.description',
  },
  {
    type: 'row',
    name: 'productsForSale',
    moduleName: 'modules.productsForSale.name',
    moduleDescr: 'modules.productsForSale.description',
  },
  {
    type: 'row',
    name: 'checkbox',
    moduleName: 'modules.checkbox.name',
    moduleDescr: 'modules.checkbox.description',
  },
  {
    type: 'row',
    name: 'payment',
    moduleName: 'modules.payment.name',
    moduleDescr: 'modules.payment.description',
  },
]
