import {
  BOOKINGS_QUERY_KEY,
  DataForCheckboxReturnReceipt,
  MOVEMENT_TYPES,
  MOVEMENTS_QUERY_KEY,
  PostingMovementInsertInput,
  ServerAccountType,
  ServerMovementStatusEnum,
  ServerTransactionByIdType,
  TRANSACTION_TYPES,
  useCheckboxCreateReturnReceipt,
  useCreateTransactionFromAccount,
  useCreateTransactionRefundBySubscription,
  useCreateTransactionRefundWithoutFromAccount,
  useDeactivateClientCards,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { generateMovementOnReturnConsumables } from '@expane/logic/movement/refund'
import { spreadPaymentItemsQuantity } from '@expane/logic/payment'
import {
  handleUpdateCreateReceiptResponse,
  transformTransactionRefundFormValuesForReturnReceipt,
} from '@expane/logic/payment/checkbox'
import { convertUnitValueToServer, UNITS_IDS } from '@expane/logic/product'
import { checkIsTransactionPaymentInCredit } from '@expane/logic/transaction'
import { TransactionRefundFormValues } from '@expane/logic/transaction/refund'
import { CloseButton, Dialog, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useAttachFunctionForModal } from 'logic/hooks/useAttachFunctionForModal'
import { generateUUIDv4 } from '@expane/web-logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, MutableRefObject } from 'react'
import { Control, SubmitHandler, UseFormHandleSubmit, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { queryClient, reportError } from 'services/api'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'

interface TransactionRefundDialogFooterProps {
  control: Control<TransactionRefundFormValues>
  handleSubmit: UseFormHandleSubmit<TransactionRefundFormValues>
  transaction: ServerTransactionByIdType
  onSuccess: () => void
  closeDialog: () => void
  totalSum: number
  isCreateRefundButton: boolean
  isSubmitting: boolean
  onSubmitForm: MutableRefObject<(() => void) | undefined>
  accounts: ServerAccountType[]
}

export const TransactionRefundDialogFooter: FC<TransactionRefundDialogFooterProps> = observer(
  ({
    control,
    handleSubmit,
    transaction,
    closeDialog,
    onSuccess,
    totalSum,
    isCreateRefundButton,
    isSubmitting,
    onSubmitForm,
    accounts,
  }) => {
    const branchId = store.branch.branchId

    const { mutateAsync: transactionFromAccount } = useCreateTransactionFromAccount()
    const { mutateAsync: transactionRefundBySubscription } =
      useCreateTransactionRefundBySubscription()
    const { mutateAsync: transactionRefundToClientBalance } =
      useCreateTransactionRefundWithoutFromAccount()
    const { mutateAsync: createReturnReceipt } = useCheckboxCreateReturnReceipt()

    const { getModuleSetting } = useBusinessModulesSettings()
    const isCheckboxEnabled = getModuleSetting('checkbox')

    const [openSnackBar] = useSnackbar()
    const { t } = useTranslation()
    const convertToMoney = useConvertNumberToMoneyCode({ branchId })

    const { mutateAsync: deactivateCards } = useDeactivateClientCards()

    const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

    const watchedRefundToClientAccount = useWatch({ control, name: 'refundToClientAccount' })

    const mutateRefundTransaction: SubmitHandler<TransactionRefundFormValues> = async data => {
      if (!branchId) {
        openSnackBar(t('transaction.refundFailed'), 'error')

        closeDialog()
        return
      }

      let result: { insertTransaction?: { id?: number } } | undefined

      const isTransactionInCredit = checkIsTransactionPaymentInCredit(transaction)

      try {
        let dataForCheckboxReturnReceipt: DataForCheckboxReturnReceipt | null = null

        if (isCheckboxEnabled && !data.refundToClientAccount && !isTransactionInCredit) {
          dataForCheckboxReturnReceipt = transformTransactionRefundFormValuesForReturnReceipt({
            data,
            totalSum,
            accounts,
            transaction,
            generateUUIDv4,
          })
        }

        let receiptId: string | undefined
        if (dataForCheckboxReturnReceipt) {
          const result = await createReturnReceipt(dataForCheckboxReturnReceipt)

          receiptId = handleUpdateCreateReceiptResponse({
            t,
            onError: message => openSnackBar(message, 'error', 3000),
            createReceiptResult: result.checkboxCreateReceipt,
          })

          if (!receiptId) return
        }

        if (data.dataType === 'product') {
          // При возврате опциональных расходников мы можем, как и в при возврате товаров, выбирать что вернуть
          // data.isRefundConsumables в defaultValues проставляется как true если есть movement
          // по этому если просто возврат товаров то всегда будет true
          let movement: { data: PostingMovementInsertInput } | null = null
          if (data.isRefundConsumables && transaction.movement?.fromStorageId) {
            movement = {
              data: {
                // возврат на тот же склад от куда была продажа
                toStorageId: transaction.movement.fromStorageId,
                status: ServerMovementStatusEnum.Completed,
                movementProducts: {
                  data: data.items.map(item => ({
                    price: item.price,
                    productId: item.id,
                    quantity: convertUnitValueToServer(item.quantity, item?.unit ?? UNITS_IDS.pcs),
                  })),
                },
                // Если эта транзакция с букингом тогда возвращаем расходники, иначе это возврат товаров от клиента
                type: transaction.bookingId
                  ? MOVEMENT_TYPES.consumablesReturn.id
                  : MOVEMENT_TYPES.customerReturn.id,
                number: '',
                branchId,
              },
            }
          }

          if (data.refundToClientAccount || isTransactionInCredit) {
            result = await transactionRefundToClientBalance({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: isTransactionInCredit
                ? TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id
                : TRANSACTION_TYPES.refund.variations.refundToClientAccount.id,
              amount: totalSum,
              clientId: transaction?.client?.id ?? null,
              bookingId: transaction?.bookingId,
              parentId: transaction?.id,
              movement,
              branchId,
            })
          } else {
            result = await transactionFromAccount({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: TRANSACTION_TYPES.refund.variations.refundFromAccount.id,
              amount: totalSum,
              fromAccountId: data.fromAccountId,
              clientId: transaction?.client?.id,
              bookingId: transaction?.bookingId,
              parentId: transaction?.id,
              movement,
              branchId,
              transactionReceipts:
                receiptId && dataForCheckboxReturnReceipt?.softwarePOSId
                  ? {
                      data: [
                        { receiptId, softwarePOSId: dataForCheckboxReturnReceipt?.softwarePOSId },
                      ],
                    }
                  : undefined,
            })
          }

          await queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        }

        if (data.dataType === 'service') {
          const spreadedServices = spreadPaymentItemsQuantity(
            data.items.map(service => ({ ...service, dataType: 'services', name: service.name })),
          )
          const movement = generateMovementOnReturnConsumables(
            data.isRefundConsumables,
            transaction,
            spreadedServices,
            branchId,
          )

          if (data.refundToClientAccount || isTransactionInCredit) {
            result = await transactionRefundToClientBalance({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: isTransactionInCredit
                ? TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id
                : TRANSACTION_TYPES.refund.variations.refundToClientAccount.id,
              amount: totalSum,
              employeeId: transaction?.employee?.id,
              clientId: transaction?.client?.id ?? null,
              bookingId: transaction?.bookingId,
              parentId: transaction?.id,
              transactionsServices: {
                data:
                  data.dataType === 'service'
                    ? spreadedServices.map(el => ({
                        serviceId: el.id,
                        price: el.price,
                      }))
                    : [],
              },
              movement,
              branchId,
            })
          } else {
            result = await transactionFromAccount({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: TRANSACTION_TYPES.refund.variations.refundFromAccount.id,
              amount: totalSum,
              fromAccountId: data.fromAccountId,
              employeeId: transaction?.employee?.id,
              clientId: transaction?.client?.id,
              bookingId: transaction?.bookingId,
              parentId: transaction?.id,
              transactionsServices: {
                data:
                  data.dataType === 'service'
                    ? spreadedServices.map(el => ({
                        serviceId: el.id,
                        price: el.price,
                      }))
                    : [],
              },
              movement,
              branchId,
              transactionReceipts:
                receiptId && dataForCheckboxReturnReceipt?.softwarePOSId
                  ? {
                      data: [
                        { receiptId, softwarePOSId: dataForCheckboxReturnReceipt?.softwarePOSId },
                      ],
                    }
                  : undefined,
            })
          }
          await queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
        }

        if (data.dataType === 'giftCard') {
          if (data.refundToClientAccount || isTransactionInCredit) {
            result = await transactionRefundToClientBalance({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: isTransactionInCredit
                ? TRANSACTION_TYPES.refund.variations.refundPaymentInCredit.id
                : TRANSACTION_TYPES.refund.variations.refundToClientAccount.id,
              amount: totalSum,
              clientId: transaction?.client?.id ?? null,
              parentId: transaction?.id,
              transactionsCards: {
                data: data.items.map(item => ({ cardId: item.id, price: item.price })),
              },
              branchId,
            })
          } else {
            result = await transactionFromAccount({
              type: TRANSACTION_TYPES.refund.id,
              typeVariation: TRANSACTION_TYPES.refund.variations.refundFromAccount.id,
              amount: totalSum,
              fromAccountId: data.fromAccountId,
              clientId: transaction?.client?.id,
              parentId: transaction?.id,
              transactionsCards: {
                data: data.items.map(item => ({ cardId: item.id, price: item.price })),
              },
              branchId,
              transactionReceipts:
                receiptId && dataForCheckboxReturnReceipt?.softwarePOSId
                  ? {
                      data: [
                        { receiptId, softwarePOSId: dataForCheckboxReturnReceipt?.softwarePOSId },
                      ],
                    }
                  : undefined,
            })
          }
          // деактивируем возвращенные карты
          if (result?.insertTransaction?.id) {
            const deactivateCardResult = await deactivateCards(data.items.map(item => item.id))
            if (!deactivateCardResult?.updateCards)
              openSnackBar(t('transaction.cardDeactivationFailed'), 'error', 3000)
          }
        }

        openSnackBar(t('transaction.refundCompletedSuccessfully'), 'success')
      } catch (error) {
        if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
        else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
        else {
          openSnackBar(t('transaction.refundFailed'), 'error')
          reportError(error, 'error', {
            data,
          })
        }
      }

      closeDialog()
      onSuccess()
    }

    const mutateRefundTransactionBySubscription: SubmitHandler<
      TransactionRefundFormValues
    > = async data => {
      if (!branchId) {
        openSnackBar(t('transaction.refundBySubscriptFailed'), 'error', 3000)
        closeDialog()

        return
      }

      let result: { insertTransaction?: { id?: number } } | undefined
      const movement = generateMovementOnReturnConsumables(
        data.isRefundConsumables,
        transaction,
        data.items,
        branchId,
      )
      if (data.refundToClientAccount) {
        result = await transactionRefundToClientBalance({
          type: TRANSACTION_TYPES.refund.id,
          typeVariation: TRANSACTION_TYPES.refund.variations.refundToClientAccount.id,
          amount: transaction.amount,
          clientId: transaction?.client?.id ?? null,
          parentId: transaction?.id,
          employeeId: transaction?.employee?.id,
          bookingId: transaction?.bookingId,
          cardId: transaction.cardId,
          transactionsServices: {
            data:
              data.dataType === 'service'
                ? data.items.map(el => ({
                    serviceId: el.id,
                    price: el.price,
                  }))
                : [],
          },
          movement,
          branchId,
        })
      } else
        result = await transactionRefundBySubscription({
          type: TRANSACTION_TYPES.refund.id,
          typeVariation: TRANSACTION_TYPES.refund.variations.refundBySubscription.id,
          amount: transaction.amount,
          employeeId: transaction?.employee?.id ?? null,
          clientId: transaction?.client?.id ?? null,
          bookingId: transaction?.bookingId,
          parentId: transaction?.id,
          cardId: transaction.cardId,
          transactionsServices: {
            data:
              data.dataType === 'service'
                ? data.items.map(el => ({
                    serviceId: el.id,
                    price: el.price,
                  }))
                : [],
          },
          movement,
          branchId,
        })

      await queryClient.invalidateQueries([BOOKINGS_QUERY_KEY])
      if (movement) await queryClient.invalidateQueries([MOVEMENTS_QUERY_KEY])

      if (result?.insertTransaction?.id)
        openSnackBar(t('transaction.refundBySubscriptCompletedSuccessfully'), 'success', 3000)
      else {
        openSnackBar(t('transaction.refundBySubscriptFailed'), 'error', 3000)
        reportError(new Error(t('transaction.refundBySubscriptFailed')), 'error', {
          data,
        })
      }

      closeDialog()
      onSuccess()
    }

    const handleOnRefund = () =>
      showConfirmation({
        title: t('transaction.creatingRefund'),
        description: transaction.cardId
          ? `${t('transaction.doYouWantToCreateRefundBySubscript')} ${
              watchedRefundToClientAccount ? t('transaction.onClientAccount') : ''
            }?`
          : `${t('transaction.doYouWantToCreateRefund')} ${convertToMoney(totalSum)} ${
              watchedRefundToClientAccount ? t('transaction.onClientAccount') : ''
            }?`,
        onConfirm: () =>
          transaction.cardId
            ? handleSubmit(mutateRefundTransactionBySubscription)()
            : handleSubmit(mutateRefundTransaction)(),
      })

    useAttachFunctionForModal(() => {
      if (isCreateRefundButton) handleOnRefund()
    }, onSubmitForm)

    return (
      <>
        <Dialog.Footer>
          {isCreateRefundButton && (
            <SaveButton
              onClick={handleOnRefund}
              spinner={isSubmitting}
              disabled={isSubmitting}
              isCreate
            />
          )}

          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
        {confirmationModal}
      </>
    )
  },
)
