import { useGetLeftovers } from '@expane/data'
import { convertUnitValueFromServer } from '@expane/logic/product'
import { PlaceholderString } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { store } from 'store'
import { ProductDto } from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'

export const LeftoverCell: FC<{ product: ProductDto; storageId: number }> = observer(
  ({ product, storageId }) => {
    const branchId = store.branch.branchId
    const { data: leftoverProducts, isLoading } = useGetLeftovers(branchId, storageId)

    if (isLoading) return <PlaceholderString width="small" />

    return (
      <div>
        {leftoverProducts && leftoverProducts[product.id]
          ? convertUnitValueFromServer(leftoverProducts[product.id].available, product.unit)
          : '-'}
      </div>
    )
  },
)
