import {
  PaymentItemsConfig,
  PaymentProviderAsClientType,
  ProviderType,
  UpdatePaymentProviderSetInput,
  useFetchBusinessModulesSettings,
  useFetchPaymentProvidersAsClient,
} from '@expane/data'
import { FormState } from 'react-hook-form'

export interface PaymentProviderFormValues {
  type: ProviderType
  name: string
  publicKey: string
  privateKey: string
  accountId: number
  allowedRefundDays: string
  paymentItemsConfig?: PaymentItemsConfig
  isChangingKeys: boolean
}

export const formDataForUpdate = (
  paymentProviderId: string,
  data: PaymentProviderFormValues,
  formState: FormState<PaymentProviderFormValues>,
): UpdatePaymentProviderSetInput => {
  const {
    name,
    publicKey,
    privateKey,
    accountId,
    allowedRefundDays,
    paymentItemsConfig,
    isChangingKeys,
  } = data

  const updatePaymentProviderSetInput: UpdatePaymentProviderSetInput = {
    id: paymentProviderId,
  }
  if (isChangingKeys) {
    updatePaymentProviderSetInput.publicKey = publicKey
    if (formState.dirtyFields.privateKey) updatePaymentProviderSetInput.privateKey = privateKey
  }
  if (formState.dirtyFields.name) updatePaymentProviderSetInput.name = name
  if (formState.dirtyFields.accountId) updatePaymentProviderSetInput.accountId = accountId
  if (formState.dirtyFields.allowedRefundDays)
    updatePaymentProviderSetInput.allowedRefundDays = Number(allowedRefundDays)
  if (formState.dirtyFields.paymentItemsConfig)
    updatePaymentProviderSetInput.paymentItemsConfig = paymentItemsConfig

  return updatePaymentProviderSetInput
}

export const isAtLeastOnePaymentProvider = (
  paymentProviders: PaymentProviderAsClientType[] | undefined,
) => (paymentProviders?.length ?? 0) > 0

export const useShowPaymentAsClient = (branchId: number | undefined) => {
  const { data: paymentProviders } = useFetchPaymentProvidersAsClient(branchId)
  const { data: modules } = useFetchBusinessModulesSettings(Boolean(branchId))

  if (!modules?.payment) return false

  return isAtLeastOnePaymentProvider(paymentProviders)
}
