import { Button, FadeInTranslateTop, modalsHTMLElement, usePopupOpenState } from '@expane/ui'
import { offset, useFloating } from '@floating-ui/react-dom'
import { FC } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  IoAddCircleOutline,
  IoCalendarOutline,
  IoPeopleOutline,
  IoReaderOutline,
} from 'react-icons/io5'
import { useOpenGroupBookingDialog } from '../../widgets/GroupBookingDialog'
import { useOpenBookingMultiServiceDialog } from '../../widgets/BookingMultiServicesDialog'
import { createCurrentDate } from '@expane/date'
import { useFetchBranchById, useFetchExtendedEmployees } from '@expane/data'
import { store } from '../../store'
import { useOpenBookingDialog } from '../../widgets/BookingDialog'

export const CreateBookingsDropdown: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const {
    branch: { branchId },
  } = store

  const { data: branch } = useFetchBranchById(branchId)

  const { data: employees } = useFetchExtendedEmployees(branch?.timezone, branchId)

  const { openCreateGroupBookingDialog, groupBookingDialog } = useOpenGroupBookingDialog()
  const { openCreateBookingMultiServicesDialog, bookingMultiServicesDialog } =
    useOpenBookingMultiServiceDialog()

  const initialStartDate = createCurrentDate(branch?.timezone)

  const { openCreateBookingDialog, bookingDialog } = useOpenBookingDialog()

  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const { reference, floating, strategy, x, y } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(5)],
  })

  const dropdownMenu = employees
    ? [
        {
          id: 1,
          name: t('booking.name'),
          Icon: IoReaderOutline,
          onClick: () =>
            openCreateBookingDialog({
              locationId: undefined,
              employeeId: employees[0].id,
              startDate: initialStartDate,
            }),
        },
        {
          id: 2,
          name: t('groupBooking.name'),
          Icon: IoPeopleOutline,
          onClick: () =>
            openCreateGroupBookingDialog({
              startDate: initialStartDate,
              employeeId: employees[0].id,
              locationId: undefined,
            }),
        },
        {
          id: 3,
          name: t('multiServicesBooking.name'),
          Icon: IoCalendarOutline,
          onClick: () =>
            openCreateBookingMultiServicesDialog({
              startDate: initialStartDate,
              employeeId: employees[0].id,
              locationId: undefined,
            }),
        },
      ]
    : []

  return (
    <div className={'relative'} ref={reference} onClick={openPopup} onMouseLeave={closePopup}>
      <Button Icon={IoAddCircleOutline} type={'outline'} className={className} twoLines>
        {t('create')}
      </Button>

      {isOpen && <div className="absolute inset-x-0 -bottom-10 h-10" />}

      {isOpen &&
        createPortal(
          <div ref={floating} style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}>
            <FadeInTranslateTop className="border-2 border-block-color bg-block rounded-md p-1">
              {dropdownMenu.map(({ Icon, name, onClick }) => (
                <button
                  key={name}
                  className={hoverMenuItemStyle}
                  role="menuitem"
                  onClick={() => {
                    closePopup()
                    onClick()
                  }}
                >
                  {Icon && <Icon size="1rem" className="mr-1" />}
                  {name}
                </button>
              ))}
            </FadeInTranslateTop>
          </div>,
          modalsHTMLElement,
        )}
      {bookingDialog}
      {bookingMultiServicesDialog}
      {groupBookingDialog}
    </div>
  )
}

const basicStyle = 'flex flex-row items-center px-1.5 py-1.5 rounded-md text-sm transition-all'
const hoverMenuItemStyle =
  'min-w-max w-full text-left text-sm text-primary-500 bg-hover hover:text-primary-700 hover:dark:text-primary-500 cursor-pointer ' +
  basicStyle
