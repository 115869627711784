import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, requestAnonymously, useMutation } from '../../api'
import {
  ServerLeadContactInsertInput,
  ServerLeadContactSetInput,
} from '../../generated/graphql-types'
import { LEADS_QUERY_KEY } from './queryKeys'
import { CloudFunctionResult } from '../responses'

export function useInsertLeadContact() {
  return useMutation(
    async (
      leadContactInsertInput: ServerLeadContactInsertInput,
    ): Promise<{ insertLeadContact: { id: number } }> => {
      return request(
        gql`
          mutation ($leadContactInsertInput: leadContact_insert_input!) {
            insertLeadContact(object: $leadContactInsertInput) {
              id
            }
          }
        `,
        {
          leadContactInsertInput,
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LEADS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateLeadContact() {
  return useMutation(
    async (dto: {
      id: number
      leadContactSetInput: ServerLeadContactSetInput
    }): Promise<{ updateLeadContactById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $leadContactSetInput: leadContact_set_input!) {
            updateLeadContactById(pk_columns: { id: $id }, _set: $leadContactSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LEADS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useAddLeadHistory() {
  return useMutation(
    async (dto: {
      leadId: string
      utmCampaign: number
      utmTags: Record<string, string>
      url: string
    }): Promise<CloudFunctionResult> => {
      return requestAnonymously(
        gql`
          mutation ($leadId: uuid!, $utmCampaign: Int!, $utmTags: jsonb!, $url: String!) {
            addLeadHistory(
              leadId: $leadId
              utmCampaign: $utmCampaign
              utmTags: $utmTags
              url: $url
            ) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([LEADS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}
