import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerBusinessLeadType } from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { businessLeadFragment } from './businessLead.fragments'
import { BUSINESS_LEADS_QUERY_KEY } from './queryKeys'

export function useFetchBusinessLeads() {
  return useQuery<ServerBusinessLeadType[]>(
    [BUSINESS_LEADS_QUERY_KEY],
    async () => {
      const result = await request(gql`
        ${businessLeadFragment}
        query {
          businessLeads(order_by: { createdAt: desc }) {
            ...businessLeadType
          }
        }
      `)

      if (Array.isArray(result?.businessLeads)) {
        return result.businessLeads
      }

      reportError(new Error('businessLeads is not an array'), 'error', { result })
      return []
    },
    {
      queryName: 'useFetchBusinessLeads',
      onSuccess: data => {
        for (const lead of data) {
          queryClient.setQueryData([BUSINESS_LEADS_QUERY_KEY, lead.id], lead)
        }
      },
      onError: reportGqlError,
    },
  )
}
