import {
  MOVEMENT_TYPES,
  PostingMovementInsertInput,
  ServerMovementProductInsertInput,
  ServerMovementStatusEnum,
  ServerTransactionByIdType,
} from '@expane/data'
import { RefundItem } from '../transaction/refund'

export const generateMovementOnReturnConsumables = (
  isRefundConsumables: boolean,
  transaction: ServerTransactionByIdType,
  refundedItems: RefundItem[],
  branchId: number,
): { data: PostingMovementInsertInput } | null => {
  // Якщо нема transaction.movement, то не було списання росхідників
  // Таков в списанні обов'язково був склад з якого списували розхідники
  if (!isRefundConsumables || !transaction.movement || !transaction.movement.fromStorageId)
    return null

  const movementProducts: ServerMovementProductInsertInput[] = []

  for (let i = 0; i < refundedItems.length; i++) {
    // Мы смотрим в возвращаемые сервисы, а не в transaction.movement,
    // что бы посчитать сколько нужно вернуть расходников
    const service = transaction.transactionsServices
      .map(tS => tS.service)
      .find(({ id }) => refundedItems[i].id === id)

    if (service) {
      for (let j = 0; j < service.serviceProducts.length; j++) {
        const consumableProductId = service.serviceProducts[j].productId
        const consumableQuantity = service.serviceProducts[j].quantity
        const returnedServiceQuantity = refundedItems[i].quantity

        const productInTransactionMovement = transaction.movement.movementProducts.find(
          item => item.productId === consumableProductId,
        )

        if (productInTransactionMovement) {
          movementProducts.push({
            productId: consumableProductId,
            price: productInTransactionMovement.price,
            // Количество = количество возвращаемых услуг умноженное на количество расходника
            // нужного для этой услуги
            quantity: consumableQuantity * returnedServiceQuantity,
            discount: productInTransactionMovement.discount,
          })
        }
      }
    }
  }

  return {
    data: {
      toStorageId: transaction.movement.fromStorageId,
      status: ServerMovementStatusEnum.Completed,
      movementProducts: { data: movementProducts },
      type: MOVEMENT_TYPES.consumablesReturn.id,
      number: '',
      branchId,
    },
  }
}
