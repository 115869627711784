import { gql } from 'graphql-request'
import { reportGqlError, request, useMutation } from '../../api'
import { ServerMovementInsertInput, ServerMovementSetInput } from '../../generated/graphql-types'
import { invalidateMovements } from './logic'
import {
  ArrivalMovementInsertInput,
  CreateProductsMovementInsertInput,
  ExpenseMovementInsertInput,
  PostingMovementInsertInput,
  ReturnProductsMovementInsertInput,
} from './mutationsTypes'

const requestCreateMovement = async (
  movementInsertInput: ServerMovementInsertInput,
): Promise<{ insertMovement: { id: number } }> => {
  return request(
    gql`
      mutation ($movementInsertInput: movement_insert_input!) {
        insertMovement(object: $movementInsertInput) {
          id
        }
      }
    `,
    { movementInsertInput },
  )
}

export const useCreateArrivalMovement = () => {
  const createMovement = (movementInsertInput: ArrivalMovementInsertInput) =>
    requestCreateMovement(movementInsertInput)

  return useMutation(createMovement, {
    onError: reportGqlError,
    onSuccess: invalidateMovements,
  })
}

export const useCreatePostingMovement = () => {
  const createMovement = (movementInsertInput: PostingMovementInsertInput) =>
    requestCreateMovement(movementInsertInput)

  return useMutation(createMovement, {
    onError: reportGqlError,
    onSuccess: invalidateMovements,
  })
}

export const useCreateExpenseMovement = () => {
  const createMovement = (movementInsertInput: ExpenseMovementInsertInput) =>
    requestCreateMovement(movementInsertInput)

  return useMutation(createMovement, {
    onError: reportGqlError,
    onSuccess: invalidateMovements,
  })
}

export const useCreateProductsMovement = () => {
  const createMovement = (movementInsertInput: CreateProductsMovementInsertInput) =>
    requestCreateMovement(movementInsertInput)

  return useMutation(createMovement, {
    onError: reportGqlError,
    onSuccess: invalidateMovements,
  })
}

export const useCreateReturnProductsMovement = () => {
  const createMovement = (movementInsertInput: ReturnProductsMovementInsertInput) =>
    requestCreateMovement(movementInsertInput)

  return useMutation(createMovement, {
    onError: reportGqlError,
    onSuccess: invalidateMovements,
  })
}

export const useUpdateMovementStatus = () => {
  return useMutation(
    (dto: {
      id: number
      movementInsertInput: Required<Pick<ServerMovementSetInput, 'status'>>
    }): Promise<{ updateMovementById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $movementInsertInput: movement_set_input!) {
            updateMovementById(pk_columns: { id: $id }, _set: $movementInsertInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: invalidateMovements,
    },
  )
}
