import { gql } from 'graphql-request'
import { reportError, reportGqlError, request, requestAnonymously, useQuery } from '../../api'
import { ServerMarketingCampaignForReferrerType } from '../../generated/graphql-types'
import {
  ANONYMOUSLY_MARKETING_CAMPAIGN_QUERY_KEY,
  MARKETING_CAMPAIGN__BY_ID_QUERY_KEY,
  MARKETING_CAMPAIGN_QUERY_KEY,
} from './queryKeys'
import { marketingCampaignForReferrerFragment } from './marketingCampaign.fragments'

export function useFetchMarketingCampaigns() {
  return useQuery<ServerMarketingCampaignForReferrerType[]>(
    [MARKETING_CAMPAIGN_QUERY_KEY],
    async () => {
      const result = await request(gql`
        ${marketingCampaignForReferrerFragment}
        query {
          marketingCampaigns {
            ...marketingCampaignForReferrerType
          }
        }
      `)

      if (Array.isArray(result?.marketingCampaigns)) {
        return result.marketingCampaigns
      }

      reportError(new Error('marketingCampaigns is not an array'), 'error', { result })
      return []
    },
    {
      queryName: 'useFetchMarketingCampaigns',
      onError: reportGqlError,
    },
  )
}

export function useFetchMarketingCampaignById(id: number | undefined) {
  return useQuery<ServerMarketingCampaignForReferrerType>(
    [MARKETING_CAMPAIGN__BY_ID_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${marketingCampaignForReferrerFragment}
          query ($id: Int!) {
            marketingCampaignById(id: $id) {
              ...marketingCampaignForReferrerType
            }
          }
        `,
        { id },
      )

      return result.marketingCampaignById
    },
    {
      enabled: Boolean(id),
      queryName: 'useFetchMarketingCampaigns',
      onError: reportGqlError,
    },
  )
}

export function useFetchMarketingCampaignsIdsAnonymously() {
  return useQuery<number[]>(
    [ANONYMOUSLY_MARKETING_CAMPAIGN_QUERY_KEY],
    async () => {
      const result = await requestAnonymously<{ marketingCampaigns?: { id: number } | null }>(gql`
        query {
          marketingCampaigns {
            id
          }
        }
      `)

      if (Array.isArray(result?.marketingCampaigns)) {
        return result.marketingCampaigns.map(campaign => campaign.id)
      }

      reportError(new Error('marketingCampaigns is not an array'), 'error', { result })
      return []
    },
    {
      queryName: 'useFetchMarketingCampaignsIdsAnonymously',
      onError: reportGqlError,
    },
  )
}
