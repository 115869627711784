export const EMAIL_FOR_SIGN_IN_STORAGE_KEY = 'emailForSignIn'
export const PRODUCTION_TEST_USER_EMAILS = [
  'tustt988@gmail.com',
  'support@ibusiness.com',
  'demo-section@expane.pro',
  'demo-clinic@expane.pro',
  'demo-salon@expane.pro',
]
export const PRODUCTION_TEST_USER_PASSWORD = 'RaWHtNKHRFufK24'

export const SERVER_STATUS_COLLECTION = 'serverStatus'
export const DOC_ID = '1'

export const TEST_USER_EMAILS = [
  'test.user@expane.pro',
  'test.client@expane.pro',
  'test.employee@expane.pro',
]
export const TEST_USER_PASSWORD = '123456'

const TIME_STEP = 0.5
// Sometimes I waited 8-9 seconds. Therefore, ~12 sec
const MAX_SEC = 12
export const WAIT_FOR_AUTH_CLAIMS_SETTINGS = {
  timeStep: TIME_STEP,
  maxSec: MAX_SEC,
  steps: MAX_SEC / TIME_STEP,
}
