import {
  MOVEMENT_TYPES,
  Scalars,
  ServerMovementBriefType,
  ServerMovementStatusEnum,
  ServerMovementType,
  TRANSACTION_TYPES,
} from '@expane/data'
import { calcProductsTotalPrice, convertUnitValueFromServer } from '../product'
import { getPercentOfAmount, roundValue } from '../utils'
import { TFunction } from 'react-i18next'

// key - productId, value - the amount of product
export type ProductsAmount = Scalars['Leftovers']
export type Leftover = ProductsAmount[keyof ProductsAmount]

export const getMovementTypesName = (id: number, t: TFunction): string => {
  const movement = Object.values(MOVEMENT_TYPES).find(type => type.id === id)

  return movement ? t(movement.name) : ''
}

export const convertTransactionMovementsQuantity = <
  T extends { movement?: ServerMovementBriefType | null },
>(
  transactions: T[],
) =>
  transactions.map(transaction => ({
    ...transaction,
    movement: {
      ...transaction.movement,
      movementProducts:
        transaction.movement?.movementProducts.map(mP => ({
          ...mP,
          quantity: convertUnitValueFromServer(mP.quantity, mP.product.unit),
        })) ?? [],
    },
  }))

export const convertMovementsQuantity = (movements: ServerMovementType[]) =>
  movements.map(movement => ({
    ...movement,
    movementProducts: movement.movementProducts
      // TODO: Перевірити аналітику по товарам коли обмежений доступ до складів https://github.com/expane-pro/expane-admin/issues/10646
      .filter(mP => mP.product)
      .map(mP => ({
        ...mP,
        quantity: convertUnitValueFromServer(mP.quantity, mP.product.unit),
      })),
  }))

const getWriteOffMovements = (movements: ServerMovementType[]) =>
  movements.filter(movement => movement.type === MOVEMENT_TYPES.writeOff.id)

export const calcWriteOffMovementProductsPrice = (movements: ServerMovementType[]): number => {
  const writeOffMovements = getWriteOffMovements(movements)

  return calcProductsTotalPrice(
    writeOffMovements.map(({ movementProducts }) => movementProducts).flat(),
  )
}

export const getFinancialInfoAboutMovement = (movement?: ServerMovementType) => {
  if (movement === undefined)
    return {
      overallPrice: 0,
      isPaid: false,
      productsWithDiscountPrice: 0,
      transportCosts: 0,
    }

  const productsPrice = calcTotalMovementProductsPrice(movement.movementProducts ?? [])

  const productsWithDiscountPrice = calcMovementTotalPrice({
    amount: productsPrice,
    discount: movement.discount ?? undefined,
    discountType: movement.discountType ?? undefined,
  })

  const transportCosts = movement.transportCosts ?? 0

  const overallPrice = productsWithDiscountPrice + transportCosts

  const totalPaidSum = getPaidSumForMovement(movement)

  const isMovementPaid = totalPaidSum >= overallPrice

  const paidSumTransport = getPaidSumTransportForMovement(movement)

  return {
    overallPrice: roundValue(overallPrice),
    isPaid: isMovementPaid,
    productsWithDiscountPrice,
    transportCosts,
    paidSumTransport,
    totalPaidSum,
  }
}

export const calcMovementTotalPrice = ({
  amount,
  discount,
  discountType,
}: {
  discount: number | undefined
  discountType: number | undefined
  amount: number
}): number => {
  const totalDiscount = discountType === 1 ? (amount * (discount ?? 0)) / 100 : discount

  return amount - (totalDiscount || 0)
}

export const calcArrivalMovementTotalPrice = ({
  amount,
  discount,
}: {
  discount: string | undefined
  amount: number
}): number => {
  if (!discount) return amount
  else return amount - getPercentOfAmount(amount, Number(discount))
}

export const calcTotalMovementProductsCostPrice = (
  products: { costPrice: number; quantity: number }[],
) => products.reduce((acc, p) => acc + p.costPrice * p.quantity, 0)

export const calcTotalMovementProductsPrice = (
  products: { price: number; quantity: number; product: { unit: number } }[],
) =>
  products.reduce(
    (acc, p) => acc + p.price * convertUnitValueFromServer(p.quantity, p.product.unit),
    0,
  )

const getPaidSumForMovement = (movement: { transactions: { amount: number }[] }) =>
  movement.transactions.reduce((sum, transaction) => sum + transaction.amount, 0)

const getPaidSumTransportForMovement = (movement: {
  transactions: { type: number; amount: number }[]
}) =>
  movement.transactions.reduce((sum, transaction) => {
    if (transaction.type === TRANSACTION_TYPES.transportCosts.id) return sum + transaction.amount
    return sum
  }, 0)

export const getMovementFromInfo = (movement?: ServerMovementType) =>
  movement?.type === MOVEMENT_TYPES.arrival.id
    ? movement?.supplier?.name
    : movement?.fromStorage?.name ?? '-'

export const getMovementToInfo = (movement?: ServerMovementType) =>
  movement?.type === MOVEMENT_TYPES.return.id
    ? movement.supplier?.name
    : movement?.toStorage?.name ?? '-'

export const MOVEMENT_DISCOUNT_TYPES = {
  percent: { id: 1, name: '%' },
  fixed: { id: 2, name: 'грн.' },
}

export const getMovementStatus = (movement: ServerMovementType | undefined, t: TFunction) => {
  if (movement === undefined) return undefined

  const status: string[] = []

  if (movement.status === ServerMovementStatusEnum.Available)
    status.push(t('movementStatus.available'))
  if (movement.status === ServerMovementStatusEnum.Reserved)
    status.push(t('movementStatus.reserved'))
  if (movement.status === ServerMovementStatusEnum.Dispatched)
    status.push(t('movementStatus.dispatched'))
  if (movement.status === ServerMovementStatusEnum.Completed)
    status.push(t('movementStatus.completed'))

  const { isPaid } = getFinancialInfoAboutMovement(movement)
  if (movement.type === MOVEMENT_TYPES.arrival.id && !isPaid) status.push(`(${t('notPaid')})`)

  return status.join(' ')
}
