import {
  useFetchBusinessLeads,
  useFetchClientById,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchClientsPhonesByClientId,
  useFetchCurrentBranchTimezone,
  useFetchTags,
} from '@expane/data'
import { addPhonesToClient } from '@expane/logic/client'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { usePopupOpenState } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, useRef, useState } from 'react'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { ClientDialogLogic, ClientDialogPlaceholder } from './ClientDialogLogic'

export interface ClientDialogInitialData {
  initialData?: { phone?: string }
}

export interface OnCreateClientDto {
  firstName: string
  lastName?: string
}

export const ClientDialog: FC<DialogProps<OnCreateClientDto> & ClientDialogInitialData> = ({
  id: initialId,
  closeDialog,
  isCreate: initialIsCreate,
  onCreate,
  initialData,
}) => {
  const [clientId, setClientId] = useState<number | undefined>(initialId)
  const [isCreate, setIsCreate] = useState<boolean>(initialIsCreate)

  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clientById, isLoading: isLoadingClientById } = useFetchClientById(
    clientId,
    timezone,
  )
  const { data: clientPhone, isLoading: isLoadingClientPhone } =
    useFetchClientsPhonesByClientId(clientId)
  const { data: tags, isLoading: isLoadingTags } = useFetchTags()
  const { data: businessLeads } = useFetchBusinessLeads()
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
  const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
  const { isLoadingBusinessModulesSettings } = useBusinessModulesSettings()

  const isLoading =
    (isLoadingClientById && !isCreate) ||
    (isLoadingClientPhone && !isCreate) ||
    isLoadingTags ||
    isLoadingClients ||
    isLoadingClientsPhones ||
    isLoadingBusinessModulesSettings ||
    !timezone

  const isNoData = !myPermissions || !businessLeads || !tags || !clients || !clientsPhones

  useErrorOpeningDialog(
    !isLoading && ((!(isCreate !== initialIsCreate) && !isCreate && !clientById) || isNoData),
    closeDialog,
  )
  if (isLoading) return <ClientDialogPlaceholder closeDialog={closeDialog} />
  else if ((!(isCreate !== initialIsCreate) && !isCreate && !clientById) || isNoData) return null

  const client = clientById ? addPhonesToClient(clientById, clientPhone) : undefined

  return (
    <ClientDialogLogic
      // Important: used for hard form reset after the client was changed
      key={clientId}
      closeDialog={closeDialog}
      isCreate={isCreate}
      timezone={timezone}
      clientById={client}
      myPermissions={myPermissions}
      leads={businessLeads}
      tags={tags}
      setClientId={setClientId}
      setIsCreate={setIsCreate}
      onCreate={onCreate}
      clients={clients}
      clientPhones={clientsPhones}
      initialData={initialData}
    />
  )
}

export interface ExtendedSelectDropdownItem extends TreeMenuItem {
  key: string
}

export const useOpenClientDialogOnCall = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const isCreate = useRef<boolean>(true)
  const telephone = useRef<string | undefined>()
  const clientId = useRef<number | undefined>()

  const openEditClientDialogOnCall = (id: number) => {
    isCreate.current = false
    clientId.current = id
    openPopup()
  }

  const openCreateClientDialogOnCall = (phone: string) => {
    isCreate.current = true
    clientId.current = undefined
    telephone.current = phone
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
  }

  const clientDialog = isOpen ? (
    <ClientDialog
      isCreate={isCreate.current}
      initialData={telephone.current ? { phone: telephone.current } : undefined}
      closeDialog={closeDialog}
      id={clientId.current}
    />
  ) : null

  return { openEditClientDialogOnCall, openCreateClientDialogOnCall, clientDialog }
}
