import { gql } from 'graphql-request'

export const marketingCampaignURLFragment = gql`
  fragment marketingCampaignURLType on marketingCampaignURL {
    id
    url
    description
  }
`

export const marketingCampaignForReferrerFragment = gql`
  ${marketingCampaignURLFragment}
  fragment marketingCampaignForReferrerType on marketingCampaign {
    id
    campaignName
    description
    type
    URLs {
      ...marketingCampaignURLType
    }
    histories {
      id
      lead {
        id
      }
    }
  }
`
