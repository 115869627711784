import {
  ServerAnonymouslyServiceType,
  ServerServiceByIdType,
  ServerServiceGroupType,
  SERVICE_TYPE,
  useFetchAllExtendedServices,
  useFetchDeactivatedServiceGroups,
  useFetchDeactivatedServices,
  useFetchServiceGroups,
} from '@expane/data'
import { Consumable } from '../payment/booking'
import { useCheckIsOneBranchInBusiness } from '../branch'
import { ItemWithModules } from '../modules'
import { findById } from '../utils'

export const getServerColorFromServices = <T extends { color?: string | null }>(services: T[]) => {
  for (const service of services) {
    if (service.color) return service.color
  }

  return 'white'
}

export const getIsServiceForSale = (serviceType: number) => serviceType === SERVICE_TYPE.forSale
export const getIsGroupService = (serviceType: number) => serviceType === SERVICE_TYPE.group

export type IdHashMap = { [id: number]: true }

export type ServicePiece = {
  bookingServices: { booking: { locationId: number; employeeId: number | null } }[]
  groupBookings: { locationId: number; employeeId: number | null }[]
}

// Get ids of all employees that were used in bookings with this service
export const getEmployeeIdsThatWereUsedHashMap = <T extends ServicePiece>(
  serviceById: T | undefined,
): IdHashMap => {
  if (!serviceById) return {}

  const { bookingServices, groupBookings } = serviceById
  const employeeIdsHash: IdHashMap = {}

  // При обмеженому доступі може бути ситуація коли booking = null
  const filteredBookingServices = bookingServices.filter(({ booking }) => Boolean(booking))

  for (const bookingService of filteredBookingServices) {
    if (bookingService.booking.employeeId && !employeeIdsHash[bookingService.booking.employeeId])
      employeeIdsHash[bookingService.booking.employeeId] = true
  }

  for (const groupBooking of groupBookings) {
    if (groupBooking.employeeId && !employeeIdsHash[groupBooking.employeeId])
      employeeIdsHash[groupBooking.employeeId] = true
  }

  return employeeIdsHash
}

// Get ids of all locations that were used in bookings with this service
export const getLocationIdsThatWereUsedHashMap = <T extends ServicePiece>(
  serviceById: T | undefined,
): IdHashMap => {
  if (!serviceById) return {}
  const { bookingServices, groupBookings } = serviceById
  const locationIdsHash: IdHashMap = {}

  // При обмеженому доступі може бути ситуація коли  booking = null
  const filteredBookingServices = bookingServices.filter(({ booking }) => Boolean(booking))

  for (const bookingService of filteredBookingServices) {
    if (!locationIdsHash[bookingService.booking.locationId])
      locationIdsHash[bookingService.booking.locationId] = true
  }

  for (const groupBooking of groupBookings) {
    if (!locationIdsHash[groupBooking.locationId]) locationIdsHash[groupBooking.locationId] = true
  }

  return locationIdsHash
}

export const getPermissionEmployeeDiff = (
  serviceById: ServerServiceByIdType, // initial
  employeesIds: number[], // after changes
) => {
  const permissionEmployeeIdsToDelete: number[] = []
  for (const { employee } of serviceById.servicePermissions) {
    if (employee)
      if (!employeesIds.some(employeeItem => employeeItem === employee.id))
        permissionEmployeeIdsToDelete.push(employee.id)
  }

  const permissionEmployeeIdsToInsert: number[] = []
  for (const employeeId of employeesIds) {
    if (
      !serviceById.servicePermissions.some(({ employee }) => employee && employee.id === employeeId)
    )
      permissionEmployeeIdsToInsert.push(employeeId)
  }

  return { permissionEmployeeIdsToDelete, permissionEmployeeIdsToInsert }
}

export const getEmployeeIdsDiff = (
  serviceEmployees: {
    id: number
    employee: { id: number }
  }[],
  employeeIds: number[],
) => {
  const serviceEmployeeIdsToDelete: number[] = []
  for (const { id, employee } of serviceEmployees) {
    if (!employeeIds.includes(employee.id)) {
      serviceEmployeeIdsToDelete.push(id)
    }
  }
  const employeeIdsToInsert: number[] = []
  for (const employeeId of employeeIds) {
    if (!serviceEmployees.some(serviceEmployee => serviceEmployee.employee.id === employeeId))
      employeeIdsToInsert.push(employeeId)
  }

  return { serviceEmployeeIdsToDelete, employeeIdsToInsert }
}

export const getLocationIdsDiff = (
  serviceLocations: { location: { id: number } }[],
  chosenLocationIds: number[],
) => {
  const locationIdsToDelete: number[] = []
  for (const { location } of serviceLocations) {
    if (!chosenLocationIds.includes(location.id)) locationIdsToDelete.push(location.id)
  }

  const locationIdsToInsert: number[] = []
  for (const locationId of chosenLocationIds) {
    if (!serviceLocations.some(serviceLocation => serviceLocation.location.id === locationId))
      locationIdsToInsert.push(locationId)
  }

  return { locationIdsToDelete, locationIdsToInsert }
}

export const getConsumablesDiff = (
  serviceProducts: {
    id: number
    productId: number
    quantity: number
    product: {
      id: number
    }
  }[],
  consumables: Consumable[],
) => {
  const consumableIdsToDelete: number[] = []

  for (const { product } of serviceProducts) {
    if (!consumables.some(consumable => consumable.productId === product.id))
      consumableIdsToDelete.push(product.id)
  }

  const consumablesToInsert: Consumable[] = []

  for (const consumable of consumables) {
    // если есть новые расходники которых не было ранее
    if (!serviceProducts.some(({ product }) => product.id === consumable.productId)) {
      consumablesToInsert.push(consumable)
    }
    // если есть расходники количество которых изменилось
    const matchedServiceProduct = serviceProducts.find(
      serviceProduct =>
        consumable.productId === serviceProduct.product.id &&
        consumable.quantity !== serviceProduct.quantity.toString(),
    )

    if (matchedServiceProduct) {
      consumableIdsToDelete.push(matchedServiceProduct.productId)
      consumablesToInsert.push({
        ...matchedServiceProduct,
        bookingProductId: consumable.bookingProductId,
        quantity: consumable.quantity.toString(),
      })
    }
  }

  return { consumableIdsToDelete, consumablesToInsert }
}

export const INTERBRANCH_SERVICE_GROUP_ID = -1

export const addGroupForInterbranchServices = <
  Service extends { id: number; groupId: number | null; availableToAllBranches: boolean },
>(
  services: Service[],
): Service[] =>
  services.map(service => ({
    ...service,
    ...(service.availableToAllBranches ? { groupId: INTERBRANCH_SERVICE_GROUP_ID } : undefined),
  }))

export const addInterBranchServiceGroup = (
  serviceGroups: ServerServiceGroupType[],
  name: string,
): ServerServiceGroupType[] => [
  {
    id: INTERBRANCH_SERVICE_GROUP_ID,
    name,
    archived: null,
    description: null,
    parentId: null,
    services: [],
  },
  ...serviceGroups,
]

export const useServiceAndGroupsWithInterBranchServiceGroup = (
  groupName: string,
  branchId: number | undefined,
) => {
  const { data: serverServices, isLoading: isLoadingServices } = useFetchAllExtendedServices(
    branchId,
    'all',
  )
  const { data: serverServiceGroups, isLoading: isLoadingServiceGroups } =
    useFetchServiceGroups(branchId)

  const { isOneBranchInBusiness, isLoading: isLoadingIsOneBranchInBusiness } =
    useCheckIsOneBranchInBusiness()

  const isLoading = isLoadingServices || isLoadingServiceGroups || isLoadingIsOneBranchInBusiness

  const services = isOneBranchInBusiness
    ? serverServices
    : addGroupForInterbranchServices(serverServices ?? [])
  const serviceGroups = isOneBranchInBusiness
    ? serverServiceGroups
    : addInterBranchServiceGroup(serverServiceGroups ?? [], groupName)

  return {
    isLoading,
    services,
    serviceGroups,
  }
}

export const useDeactivatedServiceAndGroupsWithInterBranchServiceGroup = (
  groupName: string,
  branchId: number | undefined,
) => {
  const { data: serverServices, isLoading: isLoadingServices } = useFetchDeactivatedServices(
    branchId,
    'all',
  )
  const { data: serverServiceGroups, isLoading: isLoadingServiceGroups } =
    useFetchDeactivatedServiceGroups(branchId)

  const { isOneBranchInBusiness, isLoading: isLoadingIsOneBranchInBusiness } =
    useCheckIsOneBranchInBusiness()

  const isLoading = isLoadingServices || isLoadingServiceGroups || isLoadingIsOneBranchInBusiness

  const services = isOneBranchInBusiness
    ? serverServices
    : addGroupForInterbranchServices(serverServices ?? [])
  const serviceGroups = isOneBranchInBusiness
    ? serverServiceGroups
    : addInterBranchServiceGroup(serverServiceGroups ?? [], groupName)

  return {
    isLoading,
    services,
    serviceGroups,
  }
}

export const checkIfItIsServiceTypeChangingAllowed = (
  serviceById: ServerServiceByIdType | undefined,
) => {
  const isServiceInCardTemplate = Boolean(serviceById?.cardTemplateServices.length)
  const isServiceInBookings =
    Boolean(serviceById?.bookingServices.length) || Boolean(serviceById?.groupBookings.length)
  const isServiceInTransactions = Boolean(serviceById?.transactionsServices.length)
  const isServiceTypeChangingAllowed =
    !isServiceInBookings && !isServiceInCardTemplate && !isServiceInTransactions

  return isServiceTypeChangingAllowed
}
// не учитывается что услуга может быть межфилиальная
export const getServicesWithoutGroup = <T extends { groupId: number | null }>(
  services: T[] | undefined,
) => services?.filter(service => !service.groupId)

export const getNotForSaleServices = <T extends { type: number }>(services: T[] | undefined) =>
  services?.filter(service => !getIsServiceForSale(service.type))

export const getAvailableForClientsServices = <T extends { availableForClient: boolean }>(
  services: T[] | undefined,
) => services?.filter(service => service.availableForClient)

export const SERVICE_TYPES: ItemWithModules<{ id: number; name: string; modules?: string[] }>[] = [
  { id: SERVICE_TYPE.service, name: 'serviceType.forBooking' },
  { id: SERVICE_TYPE.group, name: 'serviceType.group' },
  { id: SERVICE_TYPE.forSale, name: 'serviceType.forSale', modules: ['servicesForSale'] },
]

export const getServicesByIds = <T extends { id: number }>(
  services: T[] | undefined,
  servicesIds: Array<number>,
) => services?.filter(service => servicesIds.some(serviceId => serviceId === service.id))

export const filterServicesThatProvideThisEmployee = <
  T extends { serviceEmployees: { employeeId: number }[] },
>(
  services: T[],
  employeeId: number | null,
  disableEmployeeSelection?: boolean,
) => {
  if (!disableEmployeeSelection) return services
  if (!employeeId) return services

  return services.filter(service =>
    service.serviceEmployees.find(serviceEmployee => serviceEmployee.employeeId === employeeId),
  )
}

export const hasSelectedServicesWithoutEmployeeService = (
  selectedServiceIds: number[],
  services: ServerAnonymouslyServiceType[],
) => {
  return selectedServiceIds.some(serviceId => {
    const service = findById(serviceId, services)
    return service?.serviceEmployees.length === 0
  })
}

export const FOR_SALE_SERVICE_DEFAULT_DURATION = '15'

export const filterServicesByName = (
  services: ServerAnonymouslyServiceType[],
  searchValue: string,
) => {
  return services.filter(service => service.name.toLowerCase().includes(searchValue.toLowerCase()))
}
