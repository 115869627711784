import {
  calcDiscountSum,
  createGiftCardCode,
  DEFAULT_QUANTITY,
  DiscountInputInfo,
  roundValue,
  transformPersonName,
} from '../utils'
import {
  CARD_TYPES,
  ServerProductType,
  ServerServiceBriefWithProductAndEmployeeType,
} from '@expane/data'
import { ProductsAmount } from '../movement'
import { TFunction } from 'react-i18next'
import { convertUnitValueFromServer } from '../product'
import { ServiceItemForPayment } from './types'

export const getServicesSum = <
  T extends { price: number; quantity: number; discount: DiscountInputInfo },
>(
  services: T[],
) => {
  return services.reduce((sum, service) => {
    const totalPrice = service.price * service.quantity
    const discountSum = calcDiscountSum({
      discount: service.discount,
      price: totalPrice,
    })
    return totalPrice - discountSum + sum
  }, 0)
}

export const getCardsSum = <T extends { price: number; discount: DiscountInputInfo }>(
  subscriptions: T[],
) =>
  subscriptions.reduce((sum, subscription) => {
    const discountSum = calcDiscountSum({
      discount: subscription.discount,
      price: subscription.price,
    })
    return subscription.price - discountSum + sum
  }, 0)

export type SubscriptionWithDiscountType = {
  card: {
    data: {
      cardTemplateId: number
      clientId: number
      type: number
      cardPeriod: number
    }
  }
  price: number
  discount?: number
}

export const getSubscriptionWithDiscount = <
  T extends {
    id: number
    name: string
    price: number
    discount: DiscountInputInfo
    cardPeriod: number
  },
>(
  subscriptionItems: T[],
  clientId: number,
): SubscriptionWithDiscountType[] => {
  return subscriptionItems.map(({ discount, id, cardPeriod, price }) => {
    if (discount.value === '0')
      return {
        card: {
          data: {
            cardTemplateId: id,
            clientId,
            type: CARD_TYPES.subscription,
            cardPeriod,
          },
        },
        price,
      }

    const discountSum = calcDiscountSum({ discount, price })
    const newPrice = price - discountSum

    return {
      card: {
        data: {
          cardTemplateId: id,
          clientId,
          type: CARD_TYPES.subscription,
          cardPeriod: cardPeriod,
        },
      },
      price: newPrice,
      discount: discountSum,
    }
  })
}

export type GiftCardsWithCodeAndDiscountType = {
  card: {
    data: {
      cardTemplateId: number
      clientId: number
      type: number
      cardPeriod: number
      code: string
    }
  }
  price: number
  discount?: number
}

export const getGiftCardsWithCodeAndDiscount = <
  T extends {
    id: number
    name: string
    price: number
    discount: DiscountInputInfo
    cardPeriod: number
  },
>(
  giftCards: T[],
  clientId: number,
): GiftCardsWithCodeAndDiscountType[] => {
  return giftCards.map(({ discount, id, cardPeriod, price }) => {
    const code = createGiftCardCode()

    if (discount.value === '0')
      return {
        card: {
          data: {
            cardTemplateId: id,
            clientId,
            type: CARD_TYPES.giftCard,
            cardPeriod,
            code,
          },
        },
        price,
      }

    const discountSum = calcDiscountSum({ discount, price })
    const newPrice = price - discountSum

    return {
      card: {
        data: {
          cardTemplateId: id,
          clientId,
          type: CARD_TYPES.giftCard,
          cardPeriod: cardPeriod,
          code,
        },
      },
      price: newPrice,
      discount: discountSum,
    }
  })
}

export type ServiceWithDiscountType = {
  serviceId: number
  price: number
  costPrice: number
  discount?: number
}

export const getServicesWithDiscount = <
  T extends {
    id: number
    discount: DiscountInputInfo
    price: number
    costPrice: number
  },
>(
  serviceItems: T[],
): ServiceWithDiscountType[] => {
  const result: ServiceWithDiscountType[] = []

  for (const service of serviceItems) {
    const serviceDiscount = service.discount

    if (serviceDiscount?.value) {
      const discount = calcDiscountSum({ discount: serviceDiscount, price: service.price })
      const newPrice = service.price - discount

      result.push({
        serviceId: service.id,
        price: newPrice,
        costPrice: service.costPrice,
        discount,
      })
    } else {
      result.push({
        serviceId: service.id,
        price: service.price,
        costPrice: service.costPrice,
      })
    }
  }

  return result
}

export type ProductWithDiscountType = {
  productId: number
  price: number
  quantity: number
  unit: number
  discount?: number
}

export const getProductsWithDiscount = <
  T extends {
    id: number
    price: number
    unit: number
    discount: DiscountInputInfo
    quantity: number
  },
>(
  productItems: T[],
): ProductWithDiscountType[] => {
  const result: ProductWithDiscountType[] = []

  for (const product of productItems) {
    const productDiscount = product.discount
    const productQuantity = product.quantity

    if (productDiscount?.value) {
      const discount = calcDiscountSum({
        discount: productDiscount,
        // нужно знать скидку на 1 единицу товара
        quantity: DEFAULT_QUANTITY,
        price: product.price,
      })

      const newPrice = product.price - discount

      result.push({
        productId: product.id,
        price: newPrice,
        quantity: productQuantity,
        discount: discount,
        unit: product.unit,
      })
    } else {
      result.push({
        productId: product.id,
        price: product.price,
        quantity: productQuantity,
        unit: product.unit,
      })
    }
  }

  return result
}

export const getEmployeesForServices = (
  services: ServerServiceBriefWithProductAndEmployeeType[],
) => {
  const result: Array<{ id: number; name: string }> = []

  services.forEach(service => {
    service.serviceEmployees.forEach(({ employee }) => {
      const isDuplicate = result.some(el => el.id === employee.id)

      if (!isDuplicate)
        result.push({
          id: employee.id,
          name: transformPersonName({
            firstName: employee.firstName,
            lastName: employee.lastName,
          }),
        })
    })
  })
  return result
}

export const addInactiveReasonsAndWarningsForProducts = (
  products: ServerProductType[],
  leftovers: ProductsAmount | undefined,
  t: TFunction,
) => {
  if (!leftovers)
    return products.map(product => ({
      ...product,
      inactiveReason: t('productOutOfStorage'),
    }))

  return products.map(product => {
    const leftoverOfProduct = leftovers[product.id]?.available
    if (!leftoverOfProduct) return { ...product, inactiveReason: t('productOutOfStorage') }

    if (leftoverOfProduct < product.criticalQuantity)
      return { ...product, warningMessage: t('criticalQuantity.products') }

    return { ...product }
  })
}

export const getProductsSum = <
  T extends { price: number; discount: DiscountInputInfo; quantity: number; unit: number },
>(
  items: T[],
) => {
  const result = items.reduce((sum, item) => {
    const quantity = convertUnitValueFromServer(item.quantity, item.unit)
    const totalItemPrice = item.price * (quantity ?? 1)

    const discountSum = calcDiscountSum({
      discount: item.discount,
      price: totalItemPrice,
    })

    const itemPrice = totalItemPrice - discountSum

    return sum + itemPrice
  }, 0)

  return roundValue(result, 'hundredths')
}

export const calcTotalProductsPriceForServer = (products: ProductWithDiscountType[]) =>
  products.reduce(
    (acc, product) =>
      acc + product.price * convertUnitValueFromServer(product.quantity, product.unit),
    0,
  )

export const addServicesWithInactiveReasons = ({
  services,
  watchedServiceItems,
  watchedEmployeeId,
  t,
}: {
  services: ServerServiceBriefWithProductAndEmployeeType[] | undefined
  watchedServiceItems: ServiceItemForPayment[]
  watchedEmployeeId: number | undefined
  t: TFunction
}) => {
  if (!services) return []

  if (watchedServiceItems.length === 0) return services
  else {
    // проверки идут по первому выбранному сервису
    const firstSelectedServices = watchedServiceItems[0]

    return services.map(service => {
      // если выбранная услуга без сотрудника, то все услуги, которые "с сотрудником" будут с inactiveReason
      if (firstSelectedServices.serviceEmployees.length === 0) {
        return service.serviceEmployees.length === 0
          ? service
          : {
              ...service,
              inactiveReason: t('serviceInactiveReason.withEmployee'),
            }
      } else {
        if (watchedEmployeeId) {
          // если услуга предоставляется с выбранным сотрудником
          if (service.serviceEmployees.some(({ employee }) => employee.id === watchedEmployeeId))
            return service
          // если услуга без сотрудника, а сотрудник выбран
          else if (service.serviceEmployees.length === 0)
            return {
              ...service,
              inactiveReason: t('serviceInactiveReason.noEmployee'),
            }
          // не предоставляется с выбранным сотрудником
          else
            return {
              ...service,
              inactiveReason: t('serviceInactiveReason.otherEmployee'),
            }
        } else return service
      }
    })
  }
}

export const addInactiveReasonByConsumablesForServices = (
  services: ServerServiceBriefWithProductAndEmployeeType[],
  leftovers: ProductsAmount,
  t: TFunction,
) => {
  return services.map(service => {
    const missingProducts = service.serviceProducts.filter(serviceProduct => {
      return (leftovers[serviceProduct.productId]?.available ?? 0) < serviceProduct.quantity
    })

    if (missingProducts.length === 0) return service

    const missingProductNames = missingProducts.map(missingProduct => missingProduct.product.name)

    return {
      ...service,
      warningMessage: `${t('notEnoughConsumables')}: ` + missingProductNames.join(', '),
    }
  })
}

interface WithPrice {
  price: number
  discount: DiscountInputInfo
}

export const getAreNaughtItemsForSale = <
  TProduct extends WithPrice,
  TService extends WithPrice,
  TSubscription extends WithPrice,
  TGiftCard extends WithPrice,
>({
  watchedProducts,
  watchedServices,
  watchedSubscriptions,
  watchedGiftCards,
}: {
  watchedProducts: TProduct[]
  watchedServices: TService[]
  watchedSubscriptions?: TSubscription[]
  watchedGiftCards?: TGiftCard[]
}) => {
  const areNaughtItemsForSale = (items: { price: number; discount: { value: string } }[]) =>
    items.some(item => item.price === 0 && item.discount.value !== '100')

  return [
    watchedProducts,
    watchedServices,
    watchedSubscriptions ?? [],
    watchedGiftCards ?? [],
  ].some(areNaughtItemsForSale)
}
