import { gql } from 'graphql-request'

export const movementFragment = gql`
  fragment movementType on movement {
    id
    type
    number
    description
    status
    transportCosts
    discount
    discountType
    createdAt
    transactions {
      type
      amount
    }
    supplierId
    supplier {
      name
    }
    fromStorageId
    fromStorage {
      name
    }
    toStorageId
    toStorage {
      name
    }
    movementProducts {
      productId
      quantity
      price
      product {
        id
        name
        unit
      }
    }
    author {
      firstName
      lastName
    }
  }
`

export const movementBriefFragment = gql`
  fragment movementBriefType on movement {
    fromStorageId
    number
    type
    status
    supplier {
      name
    }
    movementProducts {
      productId
      price
      discount
      quantity
      product {
        id
        name
        unit
        forSale
      }
    }
  }
`
