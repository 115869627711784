import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerBusinessSetInput,
  ServerCreateBusinessResult,
  ServerMutationRootCreateBusinessArgs,
} from '../../generated/graphql-types'
import { BUSINESS_QUERY_KEY } from './queryKeys'

export function useBootstrapBusiness() {
  return useMutation(
    async (
      dto: ServerMutationRootCreateBusinessArgs,
    ): Promise<{
      createBusiness: ServerCreateBusinessResult
    }> => {
      return request(
        gql`
          mutation (
            $branchAddress: String
            $branchPhone: String
            $businessName: String!
            $currency: String!
            $description: String
            $lang: Languages
            $leadId: uuid
            $modulesSettings: ModulesSettingsInput
            $ownerFirstName: String!
            $ownerLastName: String!
            $ownerPhone: String
            $photo: String
            $planId: Int
            $promoCode: String
            $timezone: String!
          ) {
            createBusiness(
              branchAddress: $branchAddress
              branchPhone: $branchPhone
              businessName: $businessName
              currency: $currency
              description: $description
              lang: $lang
              leadId: $leadId
              modulesSettings: $modulesSettings
              ownerFirstName: $ownerFirstName
              ownerLastName: $ownerLastName
              ownerPhone: $ownerPhone
              photo: $photo
              planId: $planId
              promoCode: $promoCode
              timezone: $timezone
            ) {
              message
              employeeId
              businessId
              branchId
              email
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([BUSINESS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateBusinessById() {
  return useMutation(
    async (dto: {
      id: number
      businessSetInput: ServerBusinessSetInput
    }): Promise<{ updateBusinessById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $businessSetInput: business_set_input!) {
            updateBusinessById(pk_columns: { id: $id }, _set: $businessSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([BUSINESS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}
